import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Analytics: FC = () => {
	const location = useLocation();

	// fires a GTM page load event without a History Change trigger and custom GTM set up
	// https://www.analyticsmania.com/post/single-page-web-app-with-google-tag-manager/#developers-help
	useEffect(() => {
		window.dataLayer = window.dataLayer || [];

		const pageViewObj: { [key: string]: any } = {
			event: 'virtualPageView',
			// want full path name, which 'useLocation' doesn't seem to provide
			pageUrl: typeof window !== 'undefined' ? window.location.href : location.pathname
		};

		window.dataLayer.push(pageViewObj);
	}, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

	return null;
};

export default Analytics;
