import { SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store/slice';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';

// PACKAGES
import { Fade, Spinner } from 'react-bootstrap';
import HTMLFlipBook from 'react-pageflip';

// UTILS
import SvgMask from '../_Helpers/SvgMask';
import { useAudioPlayer, useWindowSize } from '../../libs/customHooks';
import CroppedImage, { generatedCropUrl } from '../_Helpers/CroppedImage';
import { guid, preloadImage } from '../../libs/utils';

// TYPES
import apibridge from '../../apibridge';
import { StaffBookViewBook, StaffBookViewTag, StudentBookViewBook, StudentBookViewTag } from '../../api/models';

// makes sure we're only using a #0 or #{odd} numbers; makes more sense for double-spreads
export const generateBookPageNumber = (currentPage: number, totalPages: number) =>
	currentPage % 2 !== 0 && currentPage + 1 < totalPages ? currentPage + 1 : currentPage;

const Tag: React.FC<{
	tag: StaffBookViewTag | StudentBookViewTag;
}> = ({ tag }) => {
	const { audio = [], heightPercent, widthPercent, xCoordinatePercent, yCoordinatePercent } = tag;

	const audioPlayer = useAudioPlayer();
	const { addToAudioQueue, clearAudioQueue, getCurrentAudioClip } = audioPlayer;
	const currentAudioClip = getCurrentAudioClip();

	const isThisTagAudioPlaying = audio.some((audioItem) => {
		return audioItem.id === currentAudioClip?.audioProps.id && audioItem.url === currentAudioClip?.audioProps.url;
	});

	const styles = {
		top: `${yCoordinatePercent}%`,
		left: `${xCoordinatePercent}%`,
		width: `${widthPercent}%`,
		height: `${heightPercent}%`
	};

	return (
		<>
			{audio.length !== 0 && (
				<button
					type="button"
					className={`hotspot btn-reset ${isThisTagAudioPlaying ? 'audio-playing' : ''}`}
					style={{ ...styles }}
					onClick={() => {
						clearAudioQueue();

						if (!isThisTagAudioPlaying) {
							for (const audioItem of audio) {
								const { id = '', url = '' } = audioItem;
								addToAudioQueue({ id, url });
							}
						}
					}}
				>
					<span className="visually-hidden">Play audio</span>
				</button>
			)}
		</>
	);
};

const Book: React.FC<{
	bookData: StaffBookViewBook | StudentBookViewBook;
	bookId: string;
	isZoomed: boolean;
	setIsZoomed: React.Dispatch<SetStateAction<boolean>>;
}> = ({ bookData, bookId, isZoomed, setIsZoomed }) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { getAudioQueue } = useAudioPlayer();
	const windowSize = useWindowSize();
	const systemInfo = useAppSelector((state) => state.systemInfo);

	const bookWidth = 1276;
	const bookHeight = 1560;
	const customWidth = bookWidth * 0.15;
	const customHeight = bookHeight * 0.15;

	const { hash } = location;
	const hashNumber = hash ? Math.floor(Math.abs(parseInt(hash.replace('#', '')))) : 0;

	const [pagesInfo, setPagesInfo] = useState({ currentPage: hashNumber, totalPages: bookData.pages?.length || 0 });
	const [isClicking, setIsClicking] = useState(false);
	const isClickingRef = useRef(false);
	const isZoomedRef = useRef(false);
	const [showZoomAlert, setShowZoomAlert] = useState(false);

	const [isPreloadingImages, setIsPreloadingImages] = useState(false);
	const [loadedPageImageTotal, setLoadedPageImageTotal] = useState(0);
	const [showBook, setShowBook] = useState(false);

	// adding additional control to the show/hide of the finish book button, since its layers means it appears in front of the book when going from last page to previous page
	const [canShowGoButton, setCanShowGoButton] = useState(false);

	const sectionRef = useRef<HTMLDivElement>(null);
	const translateFromRef = useRef({ x: 0, y: 0 });

	const book = useRef<typeof HTMLFlipBook>();
	const bookCurrent = book.current as any; // necessary since the plugin wrapper is very poorly typed

	const bookRef = useRef<HTMLDivElement>(null);

	const bookNode: HTMLDivElement | null = document.querySelector('#book');
	const bookNodeWidth = bookNode ? bookNode.clientWidth : 0;
	const windowToBookWidthDiff = (windowSize.width || 0) - bookNodeWidth;

	const isFirstPage = pagesInfo.currentPage === 0;
	const isLastPage = pagesInfo.currentPage >= pagesInfo.totalPages - 2;
	const totalPagesIsEvenNumber = pagesInfo.totalPages % 2 !== 1;
	const { pages = [] } = bookData;
	const bookImageWidth = 686 * 1.25;
	const bookImageHeight = 840 * 1.25;

	const preloadImages = async () => {
		setIsPreloadingImages(true);

		const generatedCropUrls = pages.map((page) => {
			const { image = {} } = page;

			// cropUrl we're preloading should match what gets loaded on the page, otherwise no point
			return generatedCropUrl({ isWebP: true, src: image.url || '', width: bookImageWidth, height: bookImageHeight });
		});

		await Promise.all(
			generatedCropUrls.map((cropUrl) =>
				preloadImage({ imageSrc: cropUrl }).then(() => setLoadedPageImageTotal((total) => total + 1))
			)
		);

		setIsPreloadingImages(false);
	};

	const handlePointerMove = (e: PointerEvent) => {
		if (isClickingRef.current && isZoomedRef.current) {
			// if zoomed in, pan the screen around on click and move
			const { clientX, clientY } = e;
			const fromX = translateFromRef.current.x;
			const fromY = translateFromRef.current.y;

			if (bookRef.current) {
				bookRef.current.style.translate = `${clientX - fromX}px ${clientY - fromY}px`;
			}
		}
	};

	const handlePointerDown = (e: React.PointerEvent) => {
		if (isZoomed) {
			// save the current clicked x/y position if zoomed in, so can translate FROM it when the pointer is moving
			const { clientX, clientY } = e;
			let xTranslateFrom = 0;
			let yTranslateFrom = 0;

			if (bookRef.current?.style.translate) {
				const translateFrom = bookRef.current.style.translate.split(' ');
				xTranslateFrom = parseInt(translateFrom[0]) || 0;
				yTranslateFrom = parseInt(translateFrom[1]) || 0;
			}

			translateFromRef.current = { x: clientX - xTranslateFrom, y: clientY - yTranslateFrom };
		}

		setIsClicking(true);
	};

	const handlePageFlip = {
		previous: () => {
			bookCurrent?.pageFlip()?.flipPrev();
			setCanShowGoButton(false);
		},
		next: () => bookCurrent?.pageFlip()?.flipNext()
	};

	const handleKeyDown = (e: KeyboardEvent) => {
		if (e.key === 'ArrowLeft') handlePageFlip.previous();
		if (e.key === 'ArrowRight') handlePageFlip.next();
		if (e.key === 'Escape') setIsZoomed(false);
	};

	const handlePointerUp = () => {
		setIsClicking(false);
	};

	const handleBookProgressSend = async (pageId: string) => {
		if (systemInfo.type === 'staff') return;

		const response = await apibridge.postStudentBookProgress({
			bookId,
			pageId
		});
		if (response && response.data && response.data.isError && response.data.validationErrors) {
			for (const err of response.data.validationErrors) {
				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'danger',
						heading: 'Analytics error',
						description: err.reason || 'Unknown error'
					})
				);
			}
		}
	};

	// load images first, then render the book
	const onInit = useCallback(
		(e: {
			data: {
				page: number;
				mode: string;
			};
			object: any;
		}) => {
			const totalPages = e.object.pages?.pages?.length || 0;
			setPagesInfo({ currentPage: e.data.page, totalPages });
			setCanShowGoButton(true);
			setShowBook(true);
		},
		[] // eslint-disable-line react-hooks/exhaustive-deps
	);

	const onFlip = useCallback((e: { data: number; object: any }) => {
		const totalPages = e.object.pages?.pages?.length || 0;
		setPagesInfo({ currentPage: e.data, totalPages });
		navigate(`#${e.data}`, { replace: true }); // changes the link anchor and stops the audio on page flip

		const currentPageId = pages?.[e.data].id;
		if (currentPageId) handleBookProgressSend(currentPageId);
		setCanShowGoButton(true);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		isClickingRef.current = isClicking;

		// adding/removing class via ref and not changing state because don't want book to re-render; weird resets can happen
		if (isClicking) sectionRef.current?.classList.add('is-clicking');
		else sectionRef.current?.classList.remove('is-clicking');
	}, [isClicking]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// zooming in should show alert, zooming out should clear it
		setShowZoomAlert(isZoomed);
		isZoomedRef.current = isZoomed;

		if (!isZoomed) {
			if (bookRef.current) bookRef.current.style.translate = '0px 0px';
		}
	}, [isZoomed]);

	useEffect(() => {
		// clear the 'Drag your mouse' alert upon zoom after a few seconds
		let timeoutId: NodeJS.Timeout;
		if (showZoomAlert) timeoutId = setTimeout(() => setShowZoomAlert(false), 5000);
		return () => clearTimeout(timeoutId);
	}, [showZoomAlert]);

	useEffect(() => {
		if (!isPreloadingImages) {
			document.addEventListener('keydown', handleKeyDown);
			document.addEventListener('pointerup', handlePointerUp);

			// on load, flip to the page represented in the anchor tag (if the anchor and page exists)
			// no negative or number floats in the URL anchor
			if (hashNumber && pages) {
				const bookPageNumber = generateBookPageNumber(hashNumber, pages.length);
				// book only accepts odd numbers for current page (except for 0)
				// only flip to page if page number in the anchor is valid
				if (bookPageNumber < pages.length) bookCurrent?.pageFlip()?.turnToPage(bookPageNumber);

				const currentPageId = pages?.[bookPageNumber]?.id;
				if (currentPageId) handleBookProgressSend(currentPageId);
			}
		}

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
			document.removeEventListener('pointerup', handlePointerUp);
		};
	}, [isPreloadingImages]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		document.addEventListener('pointermove', handlePointerMove);
		preloadImages();

		return () => document.removeEventListener('pointermove', handlePointerMove);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="container">
				<section ref={sectionRef} className="component-book my-4" onPointerDown={handlePointerDown}>
					<div
						ref={bookRef}
						id="book"
						className={`
							book position-relative 
							${showBook ? '' : 'opacity-0 pe-none'} 
							${isFirstPage ? 'is-first-page' : ''} 
							${isLastPage ? 'is-last-page' : ''} 
							${totalPagesIsEvenNumber ? 'total-pages-is-even' : ''} 
							${getAudioQueue().length ? 'audio-playing' : ''}
						`}
					>
						{isPreloadingImages ? null : (
							<>
								<HTMLFlipBook
									ref={book}
									startPage={pagesInfo.currentPage}
									size={'stretch'}
									width={customWidth}
									height={customHeight}
									minWidth={customWidth}
									minHeight={customHeight}
									maxWidth={bookWidth}
									maxHeight={bookHeight}
									drawShadow={true}
									flippingTime={600}
									usePortrait={true}
									startZIndex={5}
									autoSize={true}
									maxShadowOpacity={0.1}
									showCover={true}
									mobileScrollSupport={true}
									clickEventForward={false}
									// useMouseEvents={!isZoomed} // clicking book corners will still turn the page with this on and 'clickEventForward' set as false, is a plugin bug...
									useMouseEvents={false}
									swipeDistance={200}
									showPageCorners={false}
									disableFlipByClick={true}
									renderOnlyPageLengthChange={true} // will stop weird behaviour like tag flickering when clicked
									className=""
									style={{}}
									onInit={onInit}
									onFlip={onFlip}
								>
									{pages?.map((page) => {
										const { image = {}, tags } = page;

										return (
											<div key={page.id} className="page">
												{image.url && (
													<CroppedImage src={image.url} width={bookImageWidth} height={bookImageHeight} usePriority />
												)}
												{tags?.map((tag) => (
													<Tag key={tag.id} tag={tag} />
												))}
											</div>
										);
									})}
								</HTMLFlipBook>
								{/* below book layer buttons */}
								<div className="buttons-wrapper position-absolute w-100 d-flex align-items-end justify-content-between">
									<Fade in={!isFirstPage} mountOnEnter unmountOnExit>
										<button type="button" className="btn btn-icon btn-arrow-left" onClick={handlePageFlip.previous}>
											<span className="visually-hidden">Previous Page</span>
											<i></i>
										</button>
									</Fade>
									<Fade in={!isLastPage} mountOnEnter unmountOnExit>
										<button type="button" className="btn btn-icon btn-arrow-right" onClick={handlePageFlip.next}>
											<span className="visually-hidden">Previous Page</span>
											<i></i>
										</button>
									</Fade>
								</div>
								{/* above book layer buttons */}
								<div className="buttons-wrapper position-absolute z-5 w-100 d-flex align-items-end justify-content-between">
									<Fade in={isLastPage && canShowGoButton && !isZoomed} mountOnEnter unmountOnExit>
										<Link
											to={systemInfo.type === 'staff' ? 'quiz/1' : 'feedback'}
											className="btn btn-icon btn-finish-book"
											style={{
												right: `calc(${0 - windowToBookWidthDiff / 2}px)`
											}}
											// will force the page flipper to unmount, as the plugin doesn't seem to do this itself on route change with <Link>, even after .destroy() is run
											// resizing next page will return to this one
											reloadDocument
										>
											<div className="circle-illustration bg-secondary rounded-circle p-3 me-2 pe-none">
												<img
													src="/svg/book-arrow-illustration.svg"
													width={80}
													height={80}
													alt="A notepage with question marks"
												/>
											</div>
											<span className="h1 text-uppercase">Go</span>
											<i></i>
										</Link>
									</Fade>
								</div>
							</>
						)}
					</div>
					<Fade timeout={300} in={isPreloadingImages} mountOnEnter unmountOnExit>
						<div className="image-preloader position-absolute top-50 start-50 translate-middle z-5 text-center text-vivid-blue">
							<div className="d-flex flex-column align-items-center gap-3">
								<Spinner animation="border" role="status" className="spinner-blue-ring" />
								<div>
									{/* <div>
										<strong>Loading {pages?.length === 1 ? 'image' : 'images'}...</strong>
									</div> */}
									<div className="h3">
										<strong>
											{loadedPageImageTotal} / {pages.length}
										</strong>
									</div>
								</div>
							</div>
						</div>
					</Fade>
				</section>
			</div>
			<Fade timeout={5000} in={showZoomAlert} mountOnEnter unmountOnExit>
				<div className="alert-mouse-drag">
					<SvgMask path="/svg/pan.svg" width={24} height={24} />
					Drag your mouse or finger to pan the page
				</div>
			</Fade>
		</>
	);
};

export default Book;
