import { Route } from 'react-router-dom';

// UTILS
import BuyersJourneyHome from '../../pages/BuyersJourneyHome/BuyersJourneyHome';
import BuyersJourneyRegister from '../../pages/BuyersJourneyRegister/BuyersJourneyRegister';
import BuyersJourneyComplete from '../../pages/BuyersJourneyComplete/BuyersJourneyComplete';
import TrialRequestComplete from '../../pages/BuyersJourneyComplete/TrialRequestComplete';

const useBuyerRoutes = () => {
	return (
		<>
			<Route path="/" element={<BuyersJourneyHome />} />
			<Route path="/register" element={<BuyersJourneyRegister />} />
			<Route path="/complete" element={<BuyersJourneyComplete />} />
			<Route path="/trial-complete" element={<TrialRequestComplete />} />
		</>
	);
};

export default useBuyerRoutes;
