import { FC } from 'react';

// HELPERS
import { getUserDomain } from '../../libs/utils';
import ImgRem from '../../libs/imgRem';

const BuyersJourneyHome: FC = () => {
	return (
		<main className="page-buyer-journey-home">
			<div className="img-landing-page-header-wrapper">
				<img
					src="./img/buyer-landing-page-header.png"
					className="object-fit-cover user-select-none"
					alt="Tiled books background"
				/>
			</div>

			<div className="mt-4 mb-5 py-4">
				<div className="container">
					<div className="d-flex flex-column align-items-center gap-1">
						<h2 className="h3">
							<strong>Welcome to</strong>
						</h2>
						<img
							src="/svg/LLLLO-Contained-Wide-Logo.svg"
							width="231"
							height="78"
							alt="Little Learners Love Literacy logo"
							className="logo mx-auto"
						/>
					</div>
					<section>
						<div className="d-flex justify-content-center gap-3 gap-md-5 text-center py-5 my-4">
							<div>
								<a
									href={`${getUserDomain('staff')}/login`}
									className="flex-column align-items-center gap-3 no-pseudo-hover btn btn-link dark-text text-decoration-none"
								>
									<img
										src="/img/teacher-login-illustration.png"
										width="120"
										height="120"
										alt="Complete Icon"
										className="icon"
									/>
									<h3 className="h3">
										<strong>Log in as teacher</strong>
									</h3>
								</a>
							</div>
							<div className="vr opacity-25 mx-4"></div>
							<div>
								<a
									href={`${getUserDomain('student')}/login`}
									className="flex-column align-items-center gap-3 no-pseudo-hover btn btn-link dark-text text-decoration-none"
								>
									<img
										src="/img/student-login-illustration.png"
										width="120"
										height="120"
										alt="Complete Icon"
										className="icon"
									/>
									<h3 className="h3">
										<strong>Log in as student</strong>
									</h3>
								</a>
							</div>
						</div>
					</section>
					<section>
						<div className="bg-pale-blue border-light-blue border rounded-4 overflow-hidden">
							<div className="row">
								<div className="col-12 col-md-6">
									<div className="d-flex align-items-center h-100">
										<div className="d-flex flex-column gap-4 py-5 px-3 pb-3 px-md-5 pb-md-5 text-center text-md-start">
											<h3 className="h2">
												<strong>See how our online platform can help your school today.</strong>
											</h3>
											<div className="d-flex flex-wrap gap-3 justify-content-center justify-content-md-start">
												<a
													href="https://www.littlelearnersloveliteracy.com.au/pages/llll-online"
													target="_blank"
													rel="noreferrer"
													className="btn btn-primary"
												>
													Learn more
												</a>
												<a href="/register" className="btn btn-white">
													Sign up now
												</a>
											</div>
										</div>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="d-flex align-items-end h-100">
										<div className="img-screenshot-wrapper w-100 h-100">
											<img
												src="/img/buyer-app-screenshot.png"
												className="object-fit-contain"
												alt="Dashboard screenshot of Little Learners Love Literacy Online"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</main>
	);
};

export default BuyersJourneyHome;
