import { FC, ReactNode, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Location, useLocation, useNavigate } from 'react-router-dom';
import { UserSlice, UserType } from '../../../store/slice/User';

// PACKAGES
import { Modal } from 'react-bootstrap';

// UTILS
import { useWindowScroll } from '../../../libs/customHooks';
import SvgMask from '../../_Helpers/SvgMask';
import ImgRem from '../../../libs/imgRem';
import { useAppSelector } from '../../../store/slice';

const HeaderNavigationLink: FC<{
	label: string;
	urlPath: string;
	icon: { iconPath: string; iconAlt: string };
	location: Location;
}> = ({ label, urlPath, icon, location }) => {
	const { iconPath, iconAlt } = icon;
	return (
		<Link
			to={urlPath}
			className={`top-nav-link d-flex flex-column align-items-center gap-2 text-primary ${
				location.pathname.includes(urlPath) ? 'active' : ''
			}`}
		>
			<ImgRem src={iconPath} width="64" height="64" alt={iconAlt} />
			<h3 className="top-nav-link-text m-0">
				<strong>{label}</strong>
			</h3>
		</Link>
	);
};

export const SideUpDownButtons: FC = () => {
	const scrollJumpAmount = 400;
	const windowScroll = useWindowScroll();
	const hasScrolledToBottom = window.innerHeight + windowScroll.y >= document.body.offsetHeight;
	const windowCanScroll = window.innerHeight < document.body.offsetHeight;

	const handleScrollUp = () => {
		const { scrollY = 0 } = window;
		window.scrollTo({ top: scrollY - scrollJumpAmount, behavior: 'smooth' });
	};

	const handleScrollDown = () => {
		const { scrollY = 0 } = window;
		window.scrollTo({ top: scrollY + scrollJumpAmount, behavior: 'smooth' });
	};

	return windowCanScroll ? (
		<div className="side-up-down-buttons position-fixed end-0 z-1 d-grid">
			<button
				type="button"
				className="btn btn-icon align-self-start"
				disabled={windowScroll.y === 0}
				onClick={handleScrollUp}
			>
				<SvgMask path={'/svg/arrow-right.svg'} width={48} height={48} className="rotate-270" />
			</button>

			<button
				type="button"
				className="btn btn-icon align-self-end"
				disabled={hasScrolledToBottom}
				onClick={handleScrollDown}
			>
				<SvgMask path={'/svg/arrow-right.svg'} width={48} height={48} className="rotate-90" />
			</button>
		</div>
	) : null;
};

export const BooksBottom: FC = () => {
	return (
		<div className="books-bottom d-flex align-items-end justify-content-between">
			<ImgRem src="/svg/book-stack-left.svg" width="198" height="158" alt="Book stack left" />
			<ImgRem src="/svg/book-stack-right.svg" width="149" height="145" alt="Book stack right" />
		</div>
	);
};

const LayoutStudent: FC<{ children: ReactNode }> = ({ children }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const user: UserType = useAppSelector((state) => state.user);

	const [showLoginModal, setShowLoginModal] = useState(false);

	const handleLogOut = () => {
		dispatch(UserSlice.actions.logOut());
		navigate('/login');
	};

	return (
		<div className="layout-student d-flex flex-column flex-grow-1">
			<header>
				<div className="top-nav d-flex align-items-center justify-content-between gap-5">
					<Link to="/elibrary" className="logo">
						<span className="visually-hidden">Back to E-Library</span>
						<ImgRem
							src="/svg/llll-online-logo-round.svg"
							width="114"
							height="120"
							alt="Little Learners Love Literacy Online logo"
						/>
					</Link>
					<div className="d-flex flex-grow-1 justify-content-center gap-3">
						<HeaderNavigationLink
							label="Library"
							urlPath={'/elibrary'}
							icon={{ iconPath: '/svg/library.svg', iconAlt: 'Bookshelf' }}
							location={location}
						/>
						<HeaderNavigationLink
							label="Awards"
							urlPath={'/awards'}
							icon={{ iconPath: '/svg/awards.svg', iconAlt: 'Awards' }}
							location={location}
						/>
					</div>
					<button
						type="button"
						className="btn btn-profile-top-right flex-column gap-2"
						onClick={() => setShowLoginModal(true)}
					>
						<ImgRem src="/svg/student-profile.svg" width="64" height="64" alt="Stencil of a person" />
						<h3 className="top-nav-link-text m-0">
							<strong>{user.firstName}</strong>
						</h3>
					</button>
				</div>
			</header>
			<main className="d-flex flex-column flex-grow-1">{children}</main>
			<Modal
				className="modal-student-dashboard"
				show={showLoginModal}
				onHide={() => setShowLoginModal(false)}
				size="lg"
				centered
			>
				<Modal.Body className="d-flex flex-column gap-4-5">
					<button type="button" className="btn-reset close" aria-label="Close" onClick={() => setShowLoginModal(false)}>
						<SvgMask path="/svg/cross.svg" width={16} height={16} />
					</button>
					<div className="d-flex flex-column align-items-center gap-4">
						<ImgRem src="/svg/student-profile.svg" width="96" height="96" alt="Stencil of a person" />
						<h2 className="h3 m-0 text-shades-800">
							<strong>
								{user.firstName} {user.lastName}
							</strong>
						</h2>
					</div>
					<div className="modal-button-group d-flex gap-4-5">
						<button type="button" className="btn" onClick={handleLogOut}>
							<ImgRem
								src="/svg/exit.svg"
								width={96}
								height={96}
								className="object-fit-scale pe-none"
								alt="Person exiting door"
							/>
							<h3 className="m-0">
								<strong>Log out</strong>
							</h3>
						</button>
						<button type="button" className="btn" onClick={() => setShowLoginModal(false)}>
							<ImgRem
								src="/svg/stay-reading.svg"
								width={96}
								height={96}
								className="object-fit-scale pe-none"
								alt="Person reading book"
							/>
							<h3 className="m-0">
								<strong>Stay</strong>
							</h3>
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default LayoutStudent;
