import {
	Dispatch as ReactDispatch,
	FC,
	PointerEvent as ReactPointerEvent,
	useEffect,
	useRef,
	useState,
	SetStateAction,
	MutableRefObject
} from 'react';

// PACKAGES
import { Fade } from 'react-bootstrap';

// COMPONENTS
import QuizIntro from '../../components/Quiz/QuizIntro';
import QuizIntroText from '../../components/Quiz/QuizIntroText';
import QuizSubmit from '../../components/Quiz/QuizSubmit';
import { QuizStateType, RandomisedBookViewDragAndDropOption } from '../../components/_Layout/LayoutQuiz/LayoutQuiz';
import QuizNavigation from '../../components/Quiz/QuizNavigation';

// UTILS
import { useWindowSize } from '../../libs/customHooks';
import { rectangularCollision, shuffleArray } from '../../libs/utils';
import CroppedImage from '../../components/_Helpers/CroppedImage';

// TYPES
import { StaffBookViewActivity, StudentBookViewActivity } from '../../api/models';

type NodesRefs = {
	answerWrappers: HTMLDivElement[] | undefined;
	answerDropAreaNodes: HTMLDivElement[] | undefined;
	answerDashedBoxNodes: HTMLDivElement[] | undefined;
	answerTagNodes: HTMLButtonElement[] | undefined;
};

// used for storing / updating 'answerNodesRef.current'
const getQuizNodes = () => {
	const answerWrappers = [...document.querySelectorAll('[data-selector="quiz-card-wrapper"]')] as
		| HTMLDivElement[]
		| undefined;
	const answerDropAreaNodes = answerWrappers?.map((node) =>
		node.querySelector('[data-selector="quiz-card-drop-area"]')
	) as HTMLDivElement[] | undefined;
	const answerDashedBoxNodes = answerDropAreaNodes?.map((node) =>
		node.querySelector('[data-selector="quiz-card-snap-area"]')
	) as HTMLDivElement[];
	const answerTagNodes = answerWrappers?.map((node) => node.querySelector('[data-selector="answer-tag"]')) as
		| HTMLButtonElement[]
		| undefined;
	return {
		answerWrappers,
		answerDropAreaNodes,
		answerDashedBoxNodes,
		answerTagNodes
	};
};

const AnswerTag: FC<{
	option: RandomisedBookViewDragAndDropOption;
	options: RandomisedBookViewDragAndDropOption[];
	setOptions: ReactDispatch<SetStateAction<RandomisedBookViewDragAndDropOption[]>>;
	optionIndex: number;
	timesTagClicked: number;
	setTimesTagClicked: ReactDispatch<SetStateAction<number>>;
	answerNodesRef: MutableRefObject<NodesRefs | undefined>;
	windowSize: { width: number; height: number };
	hasReachedAttemptLimit: boolean;
	quizState: QuizStateType;
	setQuizState: ReactDispatch<SetStateAction<QuizStateType>>;
}> = ({
	option,
	options,
	setOptions,
	optionIndex,
	timesTagClicked,
	setTimesTagClicked,
	answerNodesRef,
	windowSize,
	hasReachedAttemptLimit,
	quizState,
	setQuizState
}) => {
	const tagRef = useRef<HTMLButtonElement>(null);
	const currentZIndex = useRef(timesTagClicked); // goes up whenever the tag is clicked to ensure it stays on top of pile
	const translateFromRef = useRef({ x: 0, y: 0 }); // storing where we're translating FROM. This is where the original click coords are stored when dragging a tag
	const answerTagBoundingRectRef = useRef(tagRef.current?.getBoundingClientRect());

	// custom object that resembles .getBoundingClientRect(), but is the answer tag position relative to the nearest 'dashed border box' rather than window
	// storing the original position (relative to the 'answer dashed box') so can check it against the window bounds and stop the user dragging outside, since the 'translate' works relative
	const answerTagInitialBoundingRectRef = useRef({ top: 0, right: 0, bottom: 0, left: 0, width: 0, height: 0 });

	const [isMounted, setIsMounted] = useState(false);
	const [hoveredBoxNode, setHoveredAnswerNode] = useState<HTMLDivElement | undefined>();
	const [selectedBoxNode, setSelectedAnswerNode] = useState<HTMLDivElement | undefined>();
	const [isClicking, setIsClicking] = useState(false);
	const { randomisedText } = option;

	const selectedAnswerIsCorrect = selectedBoxNode?.dataset.answer === randomisedText;

	// important for separating the draggable tag data from the box!
	const linkedTag = options.find((option) => option.selectedAnswerText === randomisedText);
	//

	const hasSubmitted = quizState !== 'unsubmitted';

	const getAnswerTagCSSOffset = () => {
		const answerWrapperNode = answerNodesRef.current?.answerWrappers?.[0];
		const answerTagNode = answerNodesRef.current?.answerTagNodes?.[0];
		const answerWrapperNodeStyle = answerWrapperNode ? getComputedStyle(answerWrapperNode) : null;
		const answerTagNodeStyle = answerTagNode ? getComputedStyle(answerTagNode) : null;
		const quizCardPadding = answerWrapperNodeStyle?.getPropertyValue('--quiz-card-padding');
		const quizCardTagOffset = answerWrapperNodeStyle?.getPropertyValue('--quiz-card-tag-offset');
		const quizTagBorderWidth = answerTagNodeStyle?.getPropertyValue('--quiz-tag-border-width');

		// multiplying border-width by 2 since there's a border at top + bottom
		return quizCardPadding && quizCardTagOffset && quizTagBorderWidth
			? parseInt(quizCardPadding) + parseInt(quizCardTagOffset) + parseInt(quizTagBorderWidth) * 2
			: 0;
	};

	const updateTagInitialBoundingRef = () => {
		if (!answerNodesRef.current) answerNodesRef.current = getQuizNodes();
		const dashedBoxRect = answerNodesRef.current.answerDashedBoxNodes?.[optionIndex]?.getBoundingClientRect();
		// initial bounds ref to help relative-ise the translated position based on the nearest 'dashed box'
		// not using '.getBoundingClientRect()' as this needs to be relative to a 'dashed box' rather than screen so screen resizes don't screw up the positions
		const boundingRef = {
			top: (dashedBoxRect?.y || 0) + getAnswerTagCSSOffset(),
			left: dashedBoxRect?.x || 0,
			width: dashedBoxRect?.width || 0,
			height: dashedBoxRect?.height || 0
		};
		answerTagInitialBoundingRectRef.current = {
			...boundingRef,
			right: boundingRef.left + boundingRef.width,
			bottom: boundingRef.top + boundingRef.height
		};
		answerTagBoundingRectRef.current = tagRef.current?.getBoundingClientRect();
	};

	const updateTagSnappedPosition = (answerNode: HTMLDivElement) => {
		const answerSnapArea = answerNode?.querySelector('[data-selector="quiz-card-snap-area"]') as
			| HTMLDivElement
			| undefined;
		const snapAreaRects = answerSnapArea?.getBoundingClientRect();
		const dashedBoxRect = answerNodesRef.current?.answerDashedBoxNodes?.[optionIndex].getBoundingClientRect();

		if (snapAreaRects && dashedBoxRect) {
			const x = dashedBoxRect.x;
			const y = dashedBoxRect.y + getAnswerTagCSSOffset();
			const resolvedX = snapAreaRects.x - x;
			const resolvedY = snapAreaRects.y - y;
			if (tagRef.current?.style) {
				tagRef.current.style.translate = `${resolvedX}px ${resolvedY}px`;
			}
		}
	};

	const handlePointerMove = (e: PointerEvent) => {
		const { clientX, clientY } = e;
		const fromX = translateFromRef.current.x;
		const fromY = translateFromRef.current.y;

		answerTagBoundingRectRef.current = tagRef.current?.getBoundingClientRect();

		if (
			answerTagBoundingRectRef.current &&
			answerTagInitialBoundingRectRef.current &&
			tagRef.current?.style &&
			answerNodesRef.current
		) {
			let resolvedX = clientX - fromX;
			let resolvedY = clientY - fromY;

			// original positions relative to the bounds of the screen
			const boundsTop = answerTagInitialBoundingRectRef.current.top * -1;
			const boundsRight = windowSize.width - answerTagInitialBoundingRectRef.current.right;
			const boundsBottom = windowSize.height - answerTagInitialBoundingRectRef.current.bottom;
			const boundsLeft = answerTagInitialBoundingRectRef.current.left * -1;

			// stop labels disappearing off screen edges
			if (resolvedY < boundsTop) resolvedY = boundsTop;
			if (resolvedX > boundsRight - 2) resolvedX = boundsRight - 2; // slight nudge to prevent a scrollbar
			if (resolvedY > boundsBottom) resolvedY = boundsBottom;
			if (resolvedX < boundsLeft) resolvedX = boundsLeft;

			tagRef.current.style.translate = `${resolvedX}px ${resolvedY}px`;

			// check for collisions against the dropzone nodes
			let collision: HTMLDivElement | undefined = undefined;
			if (answerNodesRef.current.answerDropAreaNodes) {
				for (const dropAreaNode of answerNodesRef.current.answerDropAreaNodes) {
					if (
						dropAreaNode &&
						rectangularCollision(answerTagBoundingRectRef.current, dropAreaNode.getBoundingClientRect())
					) {
						collision = dropAreaNode;
						break;
					}
				}
			}

			setHoveredAnswerNode(collision);
		}
	};

	const handlePointerUp = () => setIsClicking(false); // <-- will make sure any handling is scoped only to the current tag, since all tags have a 'Pointer Up' listener

	const handlePointerDown = (e: ReactPointerEvent) => {
		const { clientX, clientY } = e;
		let xTranslateFrom = 0;
		let yTranslateFrom = 0;

		if (!answerNodesRef.current) answerNodesRef.current = getQuizNodes();

		if (tagRef.current?.style.translate) {
			const translateFrom = tagRef.current.style.translate.split(' ');
			xTranslateFrom = parseInt(translateFrom[0]);
			yTranslateFrom = parseInt(translateFrom[1]);
		}

		translateFromRef.current = { x: clientX - xTranslateFrom, y: clientY - yTranslateFrom };

		setIsClicking(true);
		currentZIndex.current = timesTagClicked + 1;
		setTimesTagClicked(currentZIndex.current);
		setQuizState('unsubmitted');
	};

	useEffect(() => {
		// a window size update means that the initial relative-ised position of each of the answer tags would also have changed
		// this needs to run so the user can't drag answers outside the screen after a resize
		updateTagInitialBoundingRef();
		// if window size adjusted, update the answer positions of selected answers to match the DOM shifts
		if (selectedBoxNode) updateTagSnappedPosition(selectedBoxNode);
	}, [windowSize.width]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// updating the original question array with new answer data
		setOptions((options) =>
			options.map((opt) => {
				if (hoveredBoxNode?.dataset.answer === opt.text && !opt.selectedAnswerText) {
					return {
						...opt,
						hoveredAnswerText: hoveredBoxNode?.dataset.answer || ''
					};
				} else {
					return {
						...opt,
						hoveredAnswerText: ''
					};
				}
			})
		);
	}, [hoveredBoxNode]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isClicking) {
			document.addEventListener('pointermove', handlePointerMove);
			// when a tag is being moved around, remove the currently selected answer for the question
			setSelectedAnswerNode(undefined);
			setOptions((options) =>
				options.map((opt) => {
					if (opt.selectedAnswerText && opt.selectedAnswerText === randomisedText) {
						return {
							...opt,
							selectedAnswerText: ''
						};
					} else {
						return opt;
					}
				})
			);
		} else {
			document.removeEventListener('pointermove', handlePointerMove);
			if (hoveredBoxNode) {
				setOptions((options) =>
					options.map((opt) => {
						const updatedOption = { ...opt, hoveredAnswerText: '' };

						// only include the answer to the question for the answer tag that was placed, and if it doesn't already have an answer
						if (hoveredBoxNode.dataset.answer === updatedOption.text && !updatedOption.selectedAnswerText) {
							updateTagSnappedPosition(hoveredBoxNode);
							updatedOption.selectedAnswerText = option.randomisedText;
						}

						return updatedOption;
					})
				);
			}

			setSelectedAnswerNode(hoveredBoxNode);
			setHoveredAnswerNode(undefined);
		}
		return () => document.removeEventListener('pointermove', handlePointerMove);
	}, [isClicking]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isMounted) {
			updateTagInitialBoundingRef();
		}
	}, [isMounted]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		document.addEventListener('pointerup', handlePointerUp);
		setIsMounted(true);
		return () => document.removeEventListener('pointerup', handlePointerUp);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<button
			type="button"
			ref={tagRef}
			data-selector="answer-tag"
			data-randomised-text={randomisedText}
			className={`
				answer-tag btn 
				${hasSubmitted || linkedTag?.answerVerifiedAsCorrect ? (selectedAnswerIsCorrect ? 'correct pe-none' : 'error') : ''}
				${(hasSubmitted && hasReachedAttemptLimit) || ['showHappyFace', 'canProceed'].includes(quizState) ? 'pe-none' : ''}
			`}
			style={{ zIndex: `${currentZIndex.current}` }}
			onPointerDown={handlePointerDown}
		>
			<h2 className="m-0 pe-none">
				<strong>{randomisedText}</strong>
			</h2>
		</button>
	);
};

const QuizDragAndDrop: FC<{
	activityData: StaffBookViewActivity | StudentBookViewActivity;
	totalBookPages: number;
	bookId: string;
	questionNumber: string;
	totalQuestions: number;
}> = ({ activityData, totalBookPages, bookId, questionNumber, totalQuestions }) => {
	const { dragAndDrop, status = '' } = activityData;
	const attempts = 'attempts' in activityData ? activityData.attempts || 0 : 0;
	const maxActivityAttempts = 'maxActivityAttempts' in activityData ? activityData.maxActivityAttempts || 2 : 2;

	const [isMounted, setIsMounted] = useState(false);
	const [showIntro, setShowIntro] = useState(questionNumber === '1');
	const [showQuiz, setShowQuiz] = useState(!showIntro);
	const [quizAttempts, setQuizAttempts] = useState(attempts);
	const [hasReachedAttemptLimit, setHasReachedAttemptLimit] = useState(false);
	const [timesTagClicked, setTimesTagClicked] = useState(0); // for z-indexing... the last click tag should always appear on top of the pile
	const [options, setOptions] = useState<RandomisedBookViewDragAndDropOption[]>([]); // for the result of the shuffled items and texts

	const [quizState, setQuizState] = useState<QuizStateType>('unsubmitted');

	const answerNodesRef = useRef<NodesRefs>();

	const windowSize = useWindowSize();

	const quizAlreadyCompleted = ['Successful', 'Unsuccessful'].includes(status);

	const handleReset = () => {
		setOptions(
			options.map((opt, optIndex, optArr) => {
				const updatedOption = { ...opt };
				if (!updatedOption.answerVerifiedAsCorrect) {
					updatedOption.selectedAnswerText = '';

					// finding linked tag DOM node based on its option index, then resetting its position
					// weird flow, but this will work
					const linkedTag = optArr.find((option) => option.selectedAnswerText === updatedOption.randomisedText);
					const tagIndex = optArr.findIndex((option) => option.id === linkedTag?.id);
					const tagNode = answerNodesRef.current?.answerTagNodes?.[tagIndex];
					if (tagNode?.style) {
						tagNode.style.translate = '';
					}
				}

				return updatedOption;
			})
		);
	};

	// lock correct answers in after submission
	useEffect(() => {
		if (quizState === 'showHappyFace' || quizState === 'showSadFace') {
			setOptions(
				options.map((option) => {
					return {
						...option,
						answerVerifiedAsCorrect: option.text === option.selectedAnswerText
					};
				})
			);
		}
	}, [quizState]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isMounted) {
			const shuffledTextOptions = shuffleArray(dragAndDrop?.options?.map((option) => option.text || '') || []);
			const optionsWithRandomisedText: RandomisedBookViewDragAndDropOption[] = [];
			dragAndDrop?.options?.forEach((option, i) =>
				optionsWithRandomisedText.push({
					...option,
					randomisedText: shuffledTextOptions[i],
					hoveredAnswerText: '',
					selectedAnswerText: '',
					answerVerifiedAsCorrect: false
				})
			);
			setOptions(optionsWithRandomisedText);
		}
	}, [isMounted]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setIsMounted(true);
	}, []);

	if (!dragAndDrop) return null;

	const { question = '', questionAudioUrl = '' } = dragAndDrop;
	const allAnswersSelected = !!options.length && options.every((opt) => opt.selectedAnswerText);
	const allAnswersCorrect = !!options.length && options.every((opt) => opt.selectedAnswerText === opt.text);

	return (
		<div className={`quiz-drag-and-drop d-flex flex-grow-1 ${showQuiz ? '' : 'intro-is-showing'}`}>
			<QuizIntro
				showIntro={showIntro}
				setShowIntro={setShowIntro}
				setShowQuiz={setShowQuiz}
				questionNumber={questionNumber}
				totalQuestions={totalQuestions}
			/>
			<Fade in={showQuiz} mountOnEnter>
				<div className="position-relative d-flex flex-column flex-grow-1 justify-content-center align-items-center gap-4 text-center py-4">
					<div className="container container-large-gutters">
						<div className="row w-100">
							<div className="col-xl-8 offset-xl-2">
								<p className="text-shades-500 m-4">
									<strong>
										{questionNumber} / {totalQuestions}
									</strong>
								</p>
								<QuizIntroText questionAudioUrl={questionAudioUrl} questionText={question} />
							</div>
						</div>
					</div>
					<div className="container d-flex flex-column flex-grow-1">
						<div className="d-flex flex-column justify-content-center flex-grow-1">
							<div className="d-flex align-items-center justify-content-center gap-4">
								{options.map((option, optionIndex) => {
									const { answerVerifiedAsCorrect, id, imageUrl, selectedAnswerText, text } = option;
									const dropAreaCollided = option.hoveredAnswerText;
									const selectedAnswerIsCorrect = selectedAnswerText === text;

									return (
										<div
											key={id}
											data-selector="quiz-card-wrapper"
											className="quiz-card-wrapper position-relative d-flex flex-column align-items-center"
										>
											{(quizState !== 'unsubmitted' || answerVerifiedAsCorrect) && (
												<div className="position-absolute top-0 translate-middle-y">
													{selectedAnswerIsCorrect ? (
														<div className="circle-correct">
															<i></i>
														</div>
													) : (
														<div className="circle-incorrect">
															<i></i>
														</div>
													)}
												</div>
											)}
											<div
												data-selector="quiz-card-drop-area"
												data-answer={text}
												className={`quiz-card d-flex flex-column gap-1 ${dropAreaCollided ? 'drop-area-collided' : ''}`}
											>
												{imageUrl && (
													<CroppedImage
														src={imageUrl}
														className="quiz-card-img object-fit-scale pe-none"
														width={240}
														height={240}
														alt=""
													/>
												)}
												<div data-selector="quiz-card-snap-area" className="quiz-card-snap-area"></div>
											</div>
											<AnswerTag
												option={option}
												options={options}
												setOptions={setOptions}
												optionIndex={optionIndex}
												timesTagClicked={timesTagClicked}
												setTimesTagClicked={setTimesTagClicked}
												answerNodesRef={answerNodesRef}
												windowSize={{
													width: windowSize.width || 1920,
													height: windowSize.height || 1080
												}}
												hasReachedAttemptLimit={hasReachedAttemptLimit}
												quizState={quizState}
												setQuizState={setQuizState}
											/>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					<QuizSubmit
						hasReachedAttemptLimit={hasReachedAttemptLimit}
						quizAlreadyCompleted={quizAlreadyCompleted}
						questionNumber={questionNumber}
						totalQuestions={totalQuestions}
						bookId={bookId}
						activityId={dragAndDrop.id || ''}
						maxActivityAttempts={maxActivityAttempts}
						allAnswersSelected={allAnswersSelected}
						allAnswersCorrect={allAnswersCorrect}
						quizAttempts={quizAttempts}
						setQuizAttempts={setQuizAttempts}
						quizState={quizState}
						setQuizState={setQuizState}
						setHasReachedAttemptLimit={setHasReachedAttemptLimit}
						handleReset={handleReset}
					/>
					<QuizNavigation
						questionNumber={parseInt(questionNumber)}
						totalQuestions={totalQuestions}
						totalBookPages={totalBookPages}
					/>
				</div>
			</Fade>
		</div>
	);
};

export default QuizDragAndDrop;
