import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../store/slice';
import { ToastMessagesSlice } from '../../../store/slice/ToastMessages';

// PACKAGES
import { Spinner } from 'react-bootstrap';

// PAGES
import QuizMatchinWithLines from '../../../pages/Quiz/QuizMatchinWithLines';
import QuizDragAndDrop from '../../../pages/Quiz/QuizDragAndDrop';
import QuizSelectRightAnswer from '../../../pages/Quiz/QuizSelectRightAnswer';

// COMPONENTS
import StudentHomeButton from '../../../pages/ELibrary/Student/StudentHomeButton';

// UTILS
import { guid } from '../../../libs/utils';
import DelayedFadeIn from '../../_Helpers/DelayedFadeIn';

// TYPES
import apibridge from '../../../apibridge';
import {
	StaffBookViewActivity,
	StaffBookViewDragAndDropOption,
	StaffBookViewMatchingWithLinesOption,
	StaffBookViewPage,
	StaffBookViewSelectRightAnswerOption,
	StudentBookViewActivity,
	StudentBookViewDragAndDropOption,
	StudentBookViewMatchingWithLinesOption,
	StudentBookViewPage,
	StudentBookViewSelectRightAnswerOption
} from '../../../api/models';

export type RandomisedBookViewDragAndDropOption = (
	| StaffBookViewDragAndDropOption
	| StudentBookViewDragAndDropOption
) & {
	randomisedText: string;
	hoveredAnswerText: string;
	selectedAnswerText: string;
	answerVerifiedAsCorrect: boolean;
};

export type RandomisedBookViewSelectRightAnswerOption = (
	| StaffBookViewSelectRightAnswerOption
	| StudentBookViewSelectRightAnswerOption
) & {
	randomisedText: string;
	hoveredAnswerText: string;
	selectedAnswerText: string;
};

export type RandomisedBookViewMatchingWithLinesOption = (
	| StaffBookViewMatchingWithLinesOption
	| StudentBookViewMatchingWithLinesOption
) & {
	randomisedText: string;
	randomisedTextAudioUrl: string;
	hoveredAnswer1Text: string;
	hoveredAnswer2Text: string;
	selectedAnswer1Text: string;
	selectedAnswer2Text: string;
	answer1VerifiedAsCorrect: boolean;
	answer2VerifiedAsCorrect: boolean;
	lineCoords: {
		xStart: number;
		yStart: number;
		xEnd: number;
		yEnd: number;
	};
};

export type ActivityOption =
	| RandomisedBookViewDragAndDropOption
	| RandomisedBookViewSelectRightAnswerOption
	| RandomisedBookViewMatchingWithLinesOption;

export type QuizStateType = 'unsubmitted' | 'showSadFace' | 'showHappyFace' | 'showReset' | 'canProceed';

export const QuizTitleCardText: FC<{ questionNumber: number }> = ({ questionNumber }) => {
	return (
		<h1 className="display text-shades-800 m-0">
			{questionNumber === 1 ? "It's quiz time!" : `Question ${questionNumber}`}
		</h1>
	);
};

const LayoutQuiz: FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [, , bookIdUrlPart, , quizNumberUrlPart] = location.pathname.split('/');
	const parsedBookIdUrlPart = encodeURIComponent(bookIdUrlPart);
	const parsedQuizNumberUrlPart = encodeURIComponent(quizNumberUrlPart);

	const systemInfo = useAppSelector((state) => state.systemInfo);

	const [isLoadingActivityData, setIsLoadingActivityData] = useState(false);
	const [data, setData] = useState<{
		activityData: StaffBookViewActivity[] | StudentBookViewActivity[] | undefined;
		bookData: StaffBookViewPage[] | StudentBookViewPage[] | undefined;
	}>();
	const { activityData = [], bookData = [] } = data || {};
	const currentQuizActivity = activityData[parseInt(quizNumberUrlPart) - 1];

	// book data includes activity data
	const getBookData = async () => {
		setIsLoadingActivityData(true);

		const response =
			systemInfo.type === 'staff'
				? await apibridge.getStaffBookView(parsedBookIdUrlPart)
				: await apibridge.getStudentBookView(parsedBookIdUrlPart);
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				const { activities, pages } = response.data.result;

				// if no activities, skip to Quiz Complete screen
				if (activities?.length) {
					setData({
						activityData: activities,
						bookData: pages
					});
				} else {
					navigate('../../book-complete', { relative: 'path' });
					return;
				}
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Book error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}

			setIsLoadingActivityData(false);
		}
	};

	const postStudentQuizProgress = async () => {
		if (systemInfo.type === 'staff') return;

		const response = await apibridge.postStudentActivityStart({
			bookId: bookIdUrlPart,
			activityId: currentQuizActivity.id
		});

		if (response && response.data && response.data.isError && response.data.validationErrors) {
			for (const err of response.data.validationErrors) {
				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'danger',
						heading: 'Analytics error',
						description: err.reason || 'Unknown error'
					})
				);
			}
		}
	};

	useEffect(() => {
		if (activityData.length) {
			postStudentQuizProgress();
		}
	}, [activityData]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getBookData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="layout-quiz">
			<StudentHomeButton />

			{isLoadingActivityData ? (
				<div className="d-flex align-items-center justify-content-center flex-grow-1">
					<DelayedFadeIn>
						<Spinner animation="border" role="status">
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					</DelayedFadeIn>
				</div>
			) : (
				activityData.length &&
				currentQuizActivity &&
				(currentQuizActivity.dragAndDrop ? (
					<QuizDragAndDrop
						activityData={currentQuizActivity}
						totalBookPages={bookData.length}
						bookId={parsedBookIdUrlPart}
						questionNumber={parsedQuizNumberUrlPart}
						totalQuestions={activityData.length}
					/>
				) : currentQuizActivity.matchinWithLines ? (
					<QuizMatchinWithLines
						activityData={currentQuizActivity}
						totalBookPages={bookData.length}
						bookId={parsedBookIdUrlPart}
						questionNumber={parsedQuizNumberUrlPart}
						totalQuestions={activityData.length}
					/>
				) : currentQuizActivity.selectRightAnswer ? (
					<QuizSelectRightAnswer
						activityData={currentQuizActivity}
						totalBookPages={bookData.length}
						bookId={parsedBookIdUrlPart}
						questionNumber={parsedQuizNumberUrlPart}
						totalQuestions={activityData.length}
					/>
				) : null)
			)}
		</div>
	);
};

export default LayoutQuiz;
