import { FC } from 'react';
import { useAppSelector } from '../../store/slice';

// PACKAGES
import { Link } from 'react-router-dom';

// UTILS
import SvgMask from '../_Helpers/SvgMask';
import { generateBookPageNumber } from '../Book/Book';

export const quizQuestionRoutes = (questionNumber: number, totalQuestions: number) => {
	return {
		previousQuestionRoute: questionNumber > 1 ? `../${questionNumber - 1}` : null,
		nextQuestionRoute: `../${questionNumber < totalQuestions ? questionNumber + 1 : '../quiz-complete'}`
	};
};

const QuizNavigation: FC<{ questionNumber: number; totalQuestions: number; totalBookPages: number }> = ({
	questionNumber,
	totalQuestions,
	totalBookPages
}) => {
	const systemInfo = useAppSelector((state) => state.systemInfo);
	const isStaff = systemInfo.type === 'staff';
	const isStudent = systemInfo.type === 'student';

	const { previousQuestionRoute, nextQuestionRoute } = quizQuestionRoutes(questionNumber, totalQuestions);

	return (
		<>
			<Link
				to={
					// since teachers skip the feedback page, a staff-based previous link has to go back to the BOOK instead of the feedback page...
					previousQuestionRoute ||
					(isStaff ? `../..#${generateBookPageNumber(totalBookPages - 1, totalBookPages)}` : '../../feedback')
				}
				className="btn btn-quiz-bottom-left"
				reloadDocument
			>
				<SvgMask path="/svg/book-back.svg" width={48} height={48} />
				<span className="visually-hidden">Previous</span>
			</Link>
			{isStudent ? null : (
				<Link to={nextQuestionRoute} className="btn btn-quiz-bottom-right" reloadDocument>
					<SvgMask path="/svg/book-forward.svg" width={48} height={48} />
					<span className="visually-hidden">Next</span>
				</Link>
			)}
		</>
	);
};

export default QuizNavigation;
