import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';

// API
import apibridge from '../../apibridge';

// UTILS
import { SITE_KEY, createErrorsObject, loadRecaptchaScript, removeDuplicates } from '../../libs/utils';
import { ResponseStandardFail } from '../../libs/api';

// PACKAGES
import { Formik, Form as FormikForm, Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

// COMPONENTS
import SvgMask from '../../components/_Helpers/SvgMask';
import FormGroupWithError from '../../components/Forms/FormGroupWithError';
import ScrollToTopButton from '../../components/_Helpers/ScrollToTopButton';
import FormCustomError from '../../components/Forms/FormCustomError';

// TYPES
import {
	SharedModelsCountryStateProvince,
	SharedModelsCountry,
	SubscriptionListSubscriptionPlans,
	OrganisationSubscriptionType,
	PaymentCalculateCommand,
	PaymentCalculatePaymentCalculate,
	SubscriptionListSubscriptionPlan,
	OrganisationRegisterCommand,
	TrialRequestCommand
} from '../../api/models';

enum FormModeTypes {
	Subscription = 'Subscription',
	Trial = 'Trial'
}

const scrollToErrorElement = (errorElement: HTMLElement) => {
	const headerOffset = 100;
	const elementPosition = errorElement.getBoundingClientRect().top;
	const offsetPosition = elementPosition + window.scrollY - headerOffset;

	window.scrollTo({
		top: offsetPosition,
		behavior: 'smooth'
	});
};

const BuyersJourneyRegister: FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const organisationQueryId = queryParams.get('id') || '';
	const trialQueryId = queryParams.get('trialId') || '';

	// Initial loading state
	const [isLoading, setIsLoading] = useState(false);

	const [subscriptionFormSubmitting, setSubscriptionFormSubmitting] = useState(false);

	// Confirm Checkbox to proceed to Payment
	const [confirmTerms, setConfirmTerms] = useState(false);

	// SUBSCRIPTION PLANS
	const [subscriptionPlans, setSubscriptionPlans] = useState<SubscriptionListSubscriptionPlans>({});

	const getSubscriptionList = async () => {
		const response = await apibridge.getSubscriptionList();
		if (!response.data.isError && response.data.result) {
			setSubscriptionPlans(response.data.result);
		}
	};

	const plans = useMemo(() => {
		const allPlans = subscriptionPlans?.plans || [];

		return allPlans;
	}, [subscriptionPlans?.plans]);

	// ORGANISATION DETAILS
	const [countryList, setCountryList] = useState<SharedModelsCountry[]>([]);
	const [stateOrganisationList, setStateOrganisationList] = useState<SharedModelsCountryStateProvince[]>([]);
	const [stateMailingList, setStateMailingList] = useState<SharedModelsCountryStateProvince[]>([]);

	const [organisationDetails, setOrganisationDetails] = useState<OrganisationRegisterCommand>({});

	const getOrganisationDetails = async () => {
		const response = await apibridge.getOrganisationRegister(organisationQueryId || '', trialQueryId || '');

		if (!response.data.isError && response.data.result) {
			const countries = response?.data?.result?.countries || [];
			setCountryList(countries);

			// This only exists if we pass in organistionQueryId to the call
			const organisation = response?.data?.result?.organisation || {};

			setOrganisationDetails(organisation);
		}
	};

	// BUILD FORM INITIAL VALUES
	const [formValuesSubscription, setFormValuesSubscription] = useState<any>({
		adminFirstName: '',
		adminLastName: '',
		adminEmail: '',
		confirmAdminEmail: '',
		id: '',
		trialId: '',
		name: '',
		email: '',
		phoneNumber: '',
		subscriptionType: 'Trial',
		organisationAddress: {
			address1: '',
			address2: '',
			city: '',
			postcode: '',
			stateProvinceId: '',
			// The below values are for formik only
			stateProvinceName: '',
			countryId: '',
			countryName: ''
		},
		billingName: '',
		mailingAddress: {
			address1: '',
			address2: '',
			city: '',
			postcode: '',
			stateProvinceId: '',
			// The below values are for formik only
			stateProvinceName: '',
			countryId: '',
			countryName: ''
		},
		sameAddress: false,
		subscriptionId: '',
		promocode: '',
		captchaToken: '',
		orderNumber: ''
	});

	// Logic to work out Country and states name and Id from a state Id
	const calculateAddress = (stateProvinceId?: string) => {
		if (!stateProvinceId) return null;

		let allStatesProvinces: SharedModelsCountryStateProvince[] = [];

		countryList.forEach((country: SharedModelsCountry) => {
			country?.stateProvinces?.forEach((stateProvince: SharedModelsCountryStateProvince) => {
				allStatesProvinces.push(stateProvince);
			});
		});

		const finalAllStatesProvinces = removeDuplicates(allStatesProvinces, 'id');

		const currentStateProvince = finalAllStatesProvinces.find((province) => province.id === stateProvinceId);

		const currentStateProvinceName = currentStateProvince?.name;

		const currentCountryId = currentStateProvince.countryId;

		const currentCountry = countryList.find((country) => country.id === currentCountryId);

		const currentCountryName = currentCountry?.name;

		return { currentCountryId, currentStateProvinceName, currentCountryName };
	};

	useEffect(() => {
		setFormValuesSubscription({
			...organisationDetails,

			confirmAdminEmail: organisationQueryId ? organisationDetails.adminEmail : '',

			organisationAddress: {
				address1: organisationDetails.organisationAddress?.address1,
				address2: organisationDetails.organisationAddress?.address2,
				city: organisationDetails.organisationAddress?.city,
				postcode: organisationDetails.organisationAddress?.postcode,
				stateProvinceId: organisationDetails.organisationAddress?.stateProvinceId,
				// These below are for formiks
				stateProvinceName: calculateAddress(organisationDetails.organisationAddress?.stateProvinceId || '')
					?.currentStateProvinceName,

				countryId: calculateAddress(organisationDetails.organisationAddress?.stateProvinceId || '')?.currentCountryId,

				countryName: calculateAddress(organisationDetails.organisationAddress?.stateProvinceId || '')
					?.currentCountryName
			},
			mailingAddress: {
				address1: organisationDetails.mailingAddress?.address1,
				address2: organisationDetails.mailingAddress?.address2,
				city: organisationDetails.mailingAddress?.city,
				postcode: organisationDetails.mailingAddress?.postcode,
				stateProvinceId: organisationDetails.mailingAddress?.stateProvinceId,
				// These below are for formiks
				stateProvinceName: calculateAddress(organisationDetails.mailingAddress?.stateProvinceId || '')
					?.currentStateProvinceName,

				countryId: calculateAddress(organisationDetails.mailingAddress?.stateProvinceId || '')?.currentCountryId,

				countryName: calculateAddress(organisationDetails.mailingAddress?.stateProvinceId || '')?.currentCountryName
			}
		});
	}, [organisationDetails]); // eslint-disable-line react-hooks/exhaustive-deps

	// This records the ID of the plan that user selected
	const [planSelected, setPlanSelected] = useState('');

	// This records the details of the plan user selected
	const [planSelectedDetails, setPlanSelectedDetails] = useState<SubscriptionListSubscriptionPlan>({});

	const selectPlanHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
		e.preventDefault();

		setPlanSelected(id);

		getSummaryDetails(id, promoCode);
	};

	// Plan SVG display
	const planSVGPath = (index: number) => {
		if (index === 0) {
			return '/svg/icon-profile-single.svg';
		} else if (index === 1) {
			return '/svg/icon-profile-duo.svg';
		} else if (index > 1) {
			return '/svg/icon-profile-trio.svg';
		} else {
			return '/svg/icon-profile-single.svg';
		}
	};

	// FORM MODE
	const [formMode, setFormMode] = useState(FormModeTypes.Subscription);

	const handleChangeSubscription = (e: ChangeEvent<HTMLFormElement>) => {
		const { name, value } = e.target;

		setFormValuesSubscription({ ...formValuesSubscription, [name]: value });
	};

	// VALIDATION
	const phoneRegExp = /^(?=.*\d)[\d ]+$/; // just numbers and spaces allowed

	const emailRegExp =
		/^(([^<>()[\]\\.,;:\s@"]+[^#]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[^#]+[a-zA-Z]{2,}))$/;

	const validationSchemaSubscription = Yup.object({
		adminFirstName: Yup.string().required('Required').min(2, 'Name must be longer than 1 character'),
		adminLastName: Yup.string().required('Required').min(2, 'Name must be longer than 1 character'),
		adminEmail: Yup.string().required('Required').email('Please enter a valid email address'),
		confirmAdminEmail: Yup.string()
			.required('Required')
			.oneOf([Yup.ref('adminEmail'), null], 'Emails must match'),
		name: Yup.string().required('Required'),
		email: Yup.string().required('Required').email('Please enter a valid email address'),
		phoneNumber: Yup.string()
			.matches(phoneRegExp, 'Phone number is not valid. Check you have used numbers only')
			.required('Required'),
		subscriptionType: Yup.string().required('Required'),
		organisationAddress: Yup.object({
			address1: Yup.string().required('Required'),
			city: Yup.string().required('Required'),
			postcode: Yup.string()
				.required('Required')
				.matches(/^[\d]+$/, 'Only numbers are allowed')
				.length(4, 'Postcode must be 4 numbers'),
			// These below are for displaying details only
			countryName: Yup.string().required('Required'),
			stateProvinceName: Yup.string().required('Required')
		}),
		sameAddress: Yup.boolean(),
		billingName: Yup.string().required('Required'),
		mailingAddress: Yup.object({
			address1: Yup.string().required('Required'),
			city: Yup.string().required('Required'),
			postcode: Yup.string()
				.required('Required')
				.matches(/^[\d]+$/, 'Only numbers are allowed')
				.length(4, 'Postcode must be 4 numbers'),
			// These below are for displaying details only
			countryName: Yup.string().required('Required'),
			stateProvinceName: Yup.string().required('Required')
		})
		// orderNumber: Yup.string().when('subscriptionType', {
		// 	is: OrganisationSubscriptionType.Invoiced,
		// 	then: Yup.string().required('Required')
		// })
	});

	const initialValuesSubscription = {
		adminFirstName: formValuesSubscription.adminFirstName || '',
		adminLastName: formValuesSubscription.adminLastName || '',
		adminEmail: formValuesSubscription.adminEmail || '',
		confirmAdminEmail: formValuesSubscription.confirmAdminEmail || '',
		name: formValuesSubscription.name || '',
		email: formValuesSubscription.email || '',
		phoneNumber: formValuesSubscription.phoneNumber || '',
		subscriptionType: formValuesSubscription.subscriptionType || '',
		organisationAddress: formValuesSubscription.organisationAddress || {},
		billingName: formValuesSubscription.name || '',
		mailingAddress: formValuesSubscription.mailingAddress || {},
		sameAddress: Boolean(formValuesSubscription.sameAddress) || false,
		subscriptionId: formValuesSubscription.subscriptionId || '',
		orderNumber: formValuesSubscription.orderNumber || '',
		promocode: formValuesSubscription.promocode || ''
	};

	// SUBMIT SUBSCRIPTION
	const confirmPayment = async (values: any, captchaToken: string, formikHelpers: FormikHelpers<any>) => {
		// setIsLoading(true); // will kill the whole component
		setSubscriptionFormSubmitting(true);

		const data = {
			id: organisationQueryId || '',
			trialId: trialQueryId || '',
			adminFirstName: values.adminFirstName,
			adminLastName: values.adminLastName,
			adminEmail: values.adminEmail,
			name: values.name,
			email: values.email,
			phoneNumber: values.phoneNumber,
			subscriptionType: values.subscriptionType,
			organisationAddress: {
				address1: values.organisationAddress.address1,
				address2: values.organisationAddress.address2,
				city: values.organisationAddress.city,
				postcode: values.organisationAddress.postcode,
				stateProvinceId: values.organisationAddress.stateProvinceId
			},
			billingName: values.billingName,
			mailingAddress: {
				address1: values.mailingAddress.address1,
				address2: values.mailingAddress.address2,
				city: values.mailingAddress.city,
				postcode: values.mailingAddress.postcode,
				stateProvinceId: values.mailingAddress.stateProvinceId
			},
			sameAddress: Boolean(values.sameAddress) || false,
			subscriptionId: planSelected,
			orderNumber: values.orderNumber,
			promocode: showPromoCodeInvalidMessage ? '' : promoCode,
			captchaToken: captchaToken
		};

		const response = await apibridge.postOrganisationRegister(data);

		if (response.data.isError) {
			setSubscriptionFormSubmitting(false);

			const errsObj = createErrorsObject(response.data as ResponseStandardFail);
			formikHelpers.setErrors(errsObj);
		} else if (response.data.result) {
			const { id = '', redirectUrl } = response.data.result;
			if (redirectUrl) {
				// if paying by Credit Card, redirectUrl will go to Stripe
				window.location.href = redirectUrl;
			} else {
				// if PO, go to /complete with organisation ID
				navigate(`/complete?id=${id}`);
			}
		}
	};

	const onSubmitSubscription = async (values: any, formikHelpers: FormikHelpers<any>) => {
		await window.grecaptcha.ready(() => {
			window.grecaptcha
				.execute(SITE_KEY, { action: 'submit' })
				.then((captchaToken: string) => {
					confirmPayment(values, captchaToken, formikHelpers);
				})
				.catch(() => {
					formikHelpers.setErrors({ captchaToken: 'Recaptcha failed!' });
				});
		});
	};

	// Trial Mode
	type FormValuesTrialType = {
		confirmEmail: '';
	} & TrialRequestCommand;
	const trialFormInitialValues: FormValuesTrialType = {
		firstName: '',
		lastName: '',
		email: '',
		confirmEmail: '',
		organisation: ''
	};
	const [formValuesTrial, setFormValuesTrial] = useState(trialFormInitialValues);
	const [trialFormSubmitting, setTrialFormSubmitting] = useState(false);

	const validationSchemaTrial = Yup.object({
		firstName: Yup.string().required('Required').min(2, 'Name must be longer than 1 character'),
		lastName: Yup.string().required('Required').min(2, 'Name must be longer than 1 character'),
		email: Yup.string().required('Required').email('Please enter a valid email address'),
		confirmEmail: Yup.string()
			.required('Required')
			.oneOf([Yup.ref('email'), null], 'Emails must match'),
		organisation: Yup.string().required('Required')
	});

	const handleChangeTrial = (e: ChangeEvent<HTMLFormElement>) => {
		const { name, value } = e.target;
		setFormValuesTrial({ ...formValuesTrial, [name]: value });
	};

	const confirmTrialRequest = async (
		values: FormValuesTrialType,
		captchaToken: string,
		formikHelpers: FormikHelpers<FormValuesTrialType>
	) => {
		const { firstName, lastName, email, organisation } = values;
		const data: TrialRequestCommand = { firstName, lastName, email, organisation, captchaToken };

		const response = await apibridge.postTrialRequest(data);
		if (response && response.data) {
			if (response.data.isError) {
				const errsObj = createErrorsObject(response.data as ResponseStandardFail);
				formikHelpers.setErrors(errsObj);
			} else if (response.data.result) {
				navigate('/trial-complete', {
					state: { ...response.data.result }
				});
				return;
			}
		}

		setTrialFormSubmitting(false);
	};

	const onSubmitTrial = async (values: FormValuesTrialType, formikHelpers: FormikHelpers<FormValuesTrialType>) => {
		setTrialFormSubmitting(true);

		await window.grecaptcha.ready(() => {
			window.grecaptcha
				.execute(SITE_KEY, { action: 'submit' })
				.then((captchaToken: string) => {
					confirmTrialRequest(values, captchaToken, formikHelpers);
				})
				.catch(() => {
					formikHelpers.setErrors({ captchaToken: 'Recaptcha failed!' });
				});
		});
	};

	// PROMO CODE
	const [promoCode, setPromoCode] = useState('');

	const [isPromoCodeValid, setIsPromoCodeValid] = useState(false);

	const [showPromoCodeInvalidMessage, setShowPromoCodeInvalidMessage] = useState(false);

	const promoCodeHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();

		if (!promoCode) return;

		getSummaryDetails(planSelected, promoCode);
	};

	const promoCodeRemoveHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();

		setPromoCode('');

		setIsPromoCodeValid(false);

		getSummaryDetails(planSelected);
	};

	// SUMMARY
	const [summaryDetails, setSummaryDetails] = useState<PaymentCalculatePaymentCalculate>({});

	const getSummaryDetails = async (planId: string, promoCodeNumber?: string) => {
		setShowPromoCodeInvalidMessage(false);

		const data: PaymentCalculateCommand = {
			subscriptionId: planId,
			promocode: promoCodeNumber || ''
		};

		const response = await apibridge.postPaymentCalculate(data);

		if (response.data.validationErrors?.[0]?.name === 'Promocode') {
			setShowPromoCodeInvalidMessage(true);
		}

		if (!response.data.isError && response.data.result) {
			if (promoCodeNumber) {
				setIsPromoCodeValid(true);
			} else {
				setIsPromoCodeValid(false);
			}

			setSummaryDetails(response.data.result);
		}
	};

	const getInitialData = async () => {
		setIsLoading(true);

		await Promise.all([getSubscriptionList(), getOrganisationDetails()]);
		loadRecaptchaScript();

		setIsLoading(false);
	};

	// When the form initializes with an organisationID, we need to update planSelected and promoCode states with the values from api calls.
	useEffect(() => {
		setPlanSelected(formValuesSubscription.subscriptionId);

		setPromoCode(formValuesSubscription.promocode);
	}, [formValuesSubscription.subscriptionId, formValuesSubscription.promocode]);

	// When the form initializes with an organisationID, we need to update planSelected, and therefore we also need to update planSelectedDetails state.
	useEffect(() => {
		const currentPlan = plans.find((planItem) => planItem.id === formValuesSubscription.subscriptionId);

		setPlanSelectedDetails(currentPlan || {});
	}, [formValuesSubscription.subscriptionId, plans]);

	// When the form initializes with an organisationID, we need to get a new summary details call from the newly acquired planSelected and promoCode
	useEffect(() => {
		if (planSelected) {
			getSummaryDetails(planSelected, promoCode);
		}
	}, [planSelected]); // eslint-disable-line react-hooks/exhaustive-deps

	// quick-fix scroll-to handling for errors returned by the server
	useEffect(() => {
		if (!subscriptionFormSubmitting) {
			const errorElement: HTMLElement | null = document.querySelector('.form-group.has-error');
			if (errorElement) scrollToErrorElement(errorElement);
		}
	}, [subscriptionFormSubmitting]);

	useEffect(() => {
		getInitialData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<main className="page-buyer-journey-register">
			<div className="d-flex flex-column align-items-center">
				<img
					src="/svg/LLLLO-Contained-Wide-Logo.svg"
					width="300"
					height="93"
					alt="Little Learners Love Literacy logo"
					className="logo mx-auto"
				/>
			</div>
			<div className="container">
				<div className="form-wrapper">
					<div className="header-wrapper">
						<h2>Sign up to</h2>

						<h1>Little Learners Love Literacy Online</h1>
					</div>

					{isLoading ? (
						<div className="d-flex align-items-center justify-content-center p-5">
							<Spinner />
						</div>
					) : (
						<div className="form-content">
							<div className="heading-section">
								<h2 className="h1 form-heading">Select your subscription</h2>

								<p className="form-subheading">Choose an annual subscription that is right for your organisation.</p>
							</div>

							<div className="tab-selection">
								{
									<div className="tab-wrapper">
										<button
											className={`tab-pill ${formMode === FormModeTypes.Subscription ? 'active' : ''}`}
											onClick={() => setFormMode(FormModeTypes.Subscription)}
										>
											Subscriptions
										</button>

										<button
											className={`tab-pill ${formMode === FormModeTypes.Trial ? 'active' : ''}`}
											onClick={() => setFormMode(FormModeTypes.Trial)}
										>
											Trial
										</button>
									</div>
								}

								{formMode === FormModeTypes.Subscription && (
									<Formik
										initialValues={initialValuesSubscription}
										onSubmit={onSubmitSubscription}
										validationSchema={validationSchemaSubscription}
										enableReinitialize
										validateOnChange
										validateOnBlur
									>
										{(formik: any) => {
											// LOGIC TO WORK OUT STATE/PROVINCES WHEN UPDATING COUNTRY FOR ORGANISATION ADDRESS
											// eslint-disable-next-line
											useEffect(() => {
												// Get the Country Id
												const organisationAddressCountryId = formik.values.organisationAddress.countryId;

												// Filter out the country
												const currentCountry = countryList.find(
													(country) => country.id === organisationAddressCountryId
												);

												// Get the list of state/provinces for current Country
												const stateProvinces = currentCountry?.stateProvinces || [];

												// Update the state for State/Provinces for Organisation Address
												setStateOrganisationList(stateProvinces);

												// Revalidate the countryName field once the above values are updated by formik
												formik.setFieldTouched(formik.values.organisationAddress.countryName, true);
											}, [formik.values.organisationAddress.countryId, formik.values.organisationAddress.countryName]); // eslint-disable-line react-hooks/exhaustive-deps

											// LOGIC TO WORK OUT STATE/PROVINCES WHEN UPDATING COUNTRY FOR MAILING ADDRESS
											// eslint-disable-next-line
											useEffect(() => {
												// Get the Country Id
												const mailingAddressCountryId = formik.values.mailingAddress.countryId;

												// Filter out the country
												const currentCountry = countryList.find((country) => country.id === mailingAddressCountryId);

												// Get the list of state/provinces for current Country
												const stateProvinces = currentCountry?.stateProvinces || [];

												// Update the state for State/Provinces for Mailing Address
												setStateMailingList(stateProvinces);

												// Revalidate the countryName field once the above values are updated by formik
												formik.setFieldTouched(formik.values.mailingAddress.countryName, true);
											}, [formik.values.mailingAddress.countryId, formik.values.mailingAddress.countryName]); // eslint-disable-line react-hooks/exhaustive-deps

											// SAME ADDRESS LOGIC
											// eslint-disable-next-line
											useEffect(() => {
												if (formik.values.sameAddress === true) {
													formik.setFieldValue('billingName', formik.values.name);
													formik?.errors?.billingName && formik.setFieldTouched('billingName', true);

													formik.setFieldValue('mailingAddress.address1', formik.values.organisationAddress.address1);
													formik?.errors?.mailingAddress?.address1 &&
														formik.setFieldTouched('mailingAddress.address1', true);

													formik.setFieldValue('mailingAddress.city', formik.values.organisationAddress.city);
													formik?.errors?.mailingAddress?.city && formik.setFieldTouched('mailingAddress.city', true);

													formik.setFieldValue('mailingAddress.postcode', formik.values.organisationAddress.postcode);
													formik?.errors?.mailingAddress?.postcode &&
														formik.setFieldTouched('mailingAddress.postcode', true);

													formik.setFieldValue(
														'mailingAddress.countryName',
														formik.values.organisationAddress.countryName
													);
													formik?.errors?.mailingAddress?.countryName &&
														formik.setFieldTouched('mailingAddress.countryName', true);

													formik.setFieldValue('mailingAddress.countryId', formik.values.organisationAddress.countryId);
													formik?.errors?.mailingAddress?.countryId &&
														formik.setFieldTouched('mailingAddress.countryId', true);

													formik.setFieldValue(
														'mailingAddress.stateProvinceName',
														formik.values.organisationAddress.stateProvinceName
													);
													formik?.errors?.mailingAddress?.stateProvinceName &&
														formik.setFieldTouched('mailingAddress.stateProvinceName', true);

													formik.setFieldValue(
														'mailingAddress.stateProvinceId',
														formik.values.organisationAddress.stateProvinceId
													);
													formik?.errors?.mailingAddress?.stateProvinceId &&
														formik.setFieldTouched('mailingAddress.stateProvinceId', true);
												}
											}, [formik.values.sameAddress]); // eslint-disable-line react-hooks/exhaustive-deps

											// All logic to update corresponding fields when sameAddress is true.
											// Formik does not provide any general way to do this, we have to manually update each field.
											// eslint-disable-next-line
											useEffect(() => {
												if (formik.values.sameAddress === true) {
													formik.setFieldValue('billingName', formik.values.name);
												}
											}, [formik.values.name]); // eslint-disable-line react-hooks/exhaustive-deps

											// eslint-disable-next-line
											useEffect(() => {
												if (formik.values.sameAddress === true) {
													formik.setFieldValue('mailingAddress.address1', formik.values.organisationAddress.address1);
												}
											}, [formik.values.organisationAddress.address1]); // eslint-disable-line react-hooks/exhaustive-deps

											// eslint-disable-next-line
											useEffect(() => {
												if (formik.values.sameAddress === true) {
													formik.setFieldValue('mailingAddress.city', formik.values.organisationAddress.city);
												}
											}, [formik.values.organisationAddress.city]); // eslint-disable-line react-hooks/exhaustive-deps

											// eslint-disable-next-line
											useEffect(() => {
												if (formik.values.sameAddress === true) {
													formik.setFieldValue('mailingAddress.postcode', formik.values.organisationAddress.postcode);
												}
											}, [formik.values.organisationAddress.postcode]); // eslint-disable-line react-hooks/exhaustive-deps

											// eslint-disable-next-line
											useEffect(() => {
												if (formik.values.sameAddress === true) {
													formik.setFieldValue(
														'mailingAddress.countryName',
														formik.values.organisationAddress.countryName
													);
												}
											}, [formik.values.organisationAddress.countryName]); // eslint-disable-line react-hooks/exhaustive-deps

											// eslint-disable-next-line
											useEffect(() => {
												if (formik.values.sameAddress === true) {
													formik.setFieldValue('mailingAddress.countryId', formik.values.organisationAddress.countryId);
												}
											}, [formik.values.organisationAddress.countryId]); // eslint-disable-line react-hooks/exhaustive-deps

											// eslint-disable-next-line
											useEffect(() => {
												if (formik.values.sameAddress === true) {
													formik.setFieldValue(
														'mailingAddress.stateProvinceName',
														formik.values.organisationAddress.stateProvinceName
													);
												}
											}, [formik.values.organisationAddress.stateProvinceName]); // eslint-disable-line react-hooks/exhaustive-deps

											// eslint-disable-next-line
											useEffect(() => {
												if (formik.values.sameAddress === true) {
													formik.setFieldValue(
														'mailingAddress.stateProvinceId',
														formik.values.organisationAddress.stateProvinceId
													);
												}
											}, [formik.values.organisationAddress.stateProvinceId]); // eslint-disable-line react-hooks/exhaustive-deps

											// Scroll to first error message when formik is validating
											// eslint-disable-next-line
											useEffect(() => {
												const { isSubmitting, errors, isValidating } = formik;

												const keys = Object.keys(errors);

												if (keys.length > 0 && isSubmitting && !isValidating) {
													const selector = `[id="${keys[0]}"]`;
													const errorElement: HTMLElement | null = document.querySelector(selector);
													if (errorElement) scrollToErrorElement(errorElement);
												}
											}, [formik]);

											return (
												<FormikForm translate="yes">
													<div className="subscription-card-wrapper">
														{/* ===SUBSCRIPTION CARDS==== */}
														{plans?.length > 0 &&
															plans.map((planItem, index) => {
																const svgPath = planSVGPath(index);
																const isPlanActive = planSelected === planItem.id;

																return (
																	<article
																		className={`subscription-card ${planSelected ? 'selected-state' : ''} ${
																			isPlanActive ? 'active' : ''
																		}`}
																		key={planItem.id || index}
																	>
																		<div className="card-name">{planItem?.name}</div>

																		<div className="card-content">
																			<div className="price">
																				<h1>${planItem.cost}</h1>

																				<span className="gst-note">GST Incl.</span>
																			</div>

																			<p className="time">per year</p>

																			<div className="quantity">
																				<SvgMask path={svgPath} width={24} height={24} className="icon" />

																				<p className="text">{planItem.allocations} Users</p>
																			</div>

																			<button
																				className="btn btn-primary w-100"
																				onClick={(e) => {
																					selectPlanHandler(e, planItem.id || '');

																					setPlanSelectedDetails(planItem);
																				}}
																			>
																				{isPlanActive ? (
																					<>
																						Selected
																						<SvgMask path="/svg/check.svg" width={16} height={16} className="icon" />
																					</>
																				) : (
																					<>Select</>
																				)}
																			</button>
																		</div>
																	</article>
																);
															})}
													</div>

													{/* in case errors are returned keyed with 'null' */}
													<FormCustomError className="text-start my-2" />

													{planSelected ? (
														<>
															<div className="form-section-wrapper">
																{/* Personal Details */}
																<section className="form-section">
																	<div className="heading-section">
																		<h2 className="h1 form-heading">Let's get some details</h2>
																		<p className="form-subheading">Enter your name and email address</p>
																	</div>

																	<div className="form-fields">
																		<div className="row">
																			<div className="col">
																				<FormGroupWithError name="adminFirstName" className="text-start">
																					<label htmlFor="adminFirstName" className="form-label">
																						First Name
																					</label>

																					<Field
																						type="text"
																						id="adminFirstName"
																						name="adminFirstName"
																						className="form-control"
																					/>
																				</FormGroupWithError>
																			</div>

																			<div className="col">
																				<FormGroupWithError name="adminLastName" className="text-start">
																					<label htmlFor="adminLastName" className="form-label">
																						Last Name
																					</label>

																					<Field
																						type="text"
																						id="adminLastName"
																						name="adminLastName"
																						className="form-control"
																					/>
																				</FormGroupWithError>
																			</div>
																		</div>

																		<FormGroupWithError name="adminEmail" className="text-start">
																			<label htmlFor="adminEmail" className="form-label">
																				Email Address
																			</label>

																			<Field type="email" id="adminEmail" name="adminEmail" className="form-control" />
																		</FormGroupWithError>

																		<FormGroupWithError name="confirmAdminEmail" className="text-start mb-2">
																			<label htmlFor="confirmAdminEmail" className="form-label">
																				Confirm Email Address
																			</label>

																			<Field
																				type="email"
																				id="confirmAdminEmail"
																				name="confirmAdminEmail"
																				className="form-control"
																			/>
																		</FormGroupWithError>
																	</div>
																</section>

																{/* Organisation Details */}
																<section className="form-section">
																	<div className="heading-section">
																		<h2 className="h1 form-heading">Organisation Details</h2>
																		<p className="form-subheading">Which school or organisation are you a part of?</p>
																	</div>

																	<div className="form-fields">
																		<FormGroupWithError name="name" className="text-start">
																			<label htmlFor="name" className="form-label">
																				Organisation Name
																			</label>

																			<Field type="text" id="name" name="name" className="form-control" />
																		</FormGroupWithError>

																		<FormGroupWithError name="email" className="text-start mb-2">
																			<label htmlFor="email" className="form-label">
																				Organisation Email
																			</label>

																			<Field type="text" id="email" name="email" className="form-control" />
																		</FormGroupWithError>

																		<div className="info-box mb-4 mt-1">
																			<p className="mb-0">
																				This email will be used to send any billing and subscription information.
																			</p>
																		</div>

																		<FormGroupWithError name="phoneNumber" className="text-start">
																			<label htmlFor="phoneNumber" className="form-label">
																				Phone Number
																			</label>

																			<Field type="tel" id="phoneNumber" name="phoneNumber" className="form-control" />
																		</FormGroupWithError>

																		<FormGroupWithError name="organisationAddress.address1" className="text-start">
																			<label htmlFor="organisationAddress.address1" className="form-label">
																				Organisation Address
																			</label>

																			<Field
																				type="text"
																				id="organisationAddress.address1"
																				name="organisationAddress.address1"
																				className="form-control"
																			/>
																		</FormGroupWithError>

																		<div className="row">
																			<div className="col">
																				<FormGroupWithError name="organisationAddress.city" className="text-start">
																					<label htmlFor="organisationAddress.city" className="form-label">
																						City
																					</label>

																					<Field
																						type="text"
																						id="organisationAddress.city"
																						name="organisationAddress.city"
																						className="form-control"
																					/>
																				</FormGroupWithError>
																			</div>

																			<div className="col">
																				<FormGroupWithError name="organisationAddress.postcode" className="text-start">
																					<label htmlFor="last-name" className="form-label">
																						Postcode
																					</label>

																					<Field
																						type="text"
																						id="organisationAddress.postcode"
																						name="organisationAddress.postcode"
																						className="form-control"
																					/>
																				</FormGroupWithError>
																			</div>
																		</div>

																		<div className="row">
																			<div className="col">
																				<FormGroupWithError
																					name="organisationAddress.countryName"
																					className="text-start"
																				>
																					<label htmlFor="organisationAddress.countryName" className="form-label">
																						Country
																					</label>

																					<select
																						id="organisationAddress.countryName"
																						className="form-select"
																						onChange={(e) => {
																							formik.setFieldValue(
																								'organisationAddress.countryName',
																								countryList.find((country) => country.id === e.target.value)?.name
																							);

																							formik.setFieldValue('organisationAddress.countryId', e.target.value);
																						}}
																					>
																						<option value="">Select</option>

																						{countryList?.length > 0 &&
																							countryList.map((country, index) => {
																								return (
																									<option key={country.id} value={country.id}>
																										{country.name}
																									</option>
																								);
																							})}
																					</select>
																				</FormGroupWithError>
																			</div>

																			<div className="col">
																				<FormGroupWithError
																					name="organisationAddress.stateProvinceName"
																					className="text-start"
																				>
																					<label htmlFor="organisationAddress.stateProvinceName" className="form-label">
																						State/Province
																					</label>

																					<select
																						id="organisationAddress.stateProvinceName"
																						className="form-select"
																						disabled={!formik.values.organisationAddress.countryName}
																						onChange={(e) => {
																							formik.setFieldValue(
																								'organisationAddress.stateProvinceName',
																								stateOrganisationList.find((state) => state.id === e.target.value)?.name
																							);

																							formik.setFieldValue(
																								'organisationAddress.stateProvinceId',
																								e.target.value
																							);
																						}}
																					>
																						<option value="">Select</option>

																						{stateOrganisationList?.map((state) => {
																							return (
																								<option key={state.id} value={state.id}>
																									{state.name}
																								</option>
																							);
																						})}
																					</select>
																				</FormGroupWithError>
																			</div>
																		</div>
																	</div>
																</section>

																{/* Billings Details */}
																<section className="form-section">
																	<div className="heading-section">
																		<h2 className="h1 form-heading">Billing & Payment Details</h2>
																		<p className="form-subheading">
																			Enter the billing details and payment method to complete the subscription.
																		</p>
																	</div>

																	<div className="form-fields">
																		<FormGroupWithError name="sameAddress" className="mb-5 mt-4">
																			<label className="form-control-checkbox">
																				<Field name="sameAddress" type="checkbox" />

																				<span>Billing details same as organisation details</span>
																			</label>
																		</FormGroupWithError>

																		<FormGroupWithError name="billingName" className="text-start">
																			<label htmlFor="billingName" className="form-label">
																				Billing Name
																			</label>

																			<Field
																				type="text"
																				id="billingName"
																				name="billingName"
																				className="form-control"
																				disabled={formik.values.sameAddress}
																			/>
																		</FormGroupWithError>

																		<FormGroupWithError name="mailingAddress.address1" className="text-start">
																			<label htmlFor="mailingAddress.address1" className="form-label">
																				Billing Address
																			</label>

																			<Field
																				type="text"
																				id="mailingAddress.address1"
																				name="mailingAddress.address1"
																				className="form-control"
																				disabled={formik.values.sameAddress}
																			/>
																		</FormGroupWithError>

																		<div className="row">
																			<div className="col">
																				<FormGroupWithError name="mailingAddress.city" className="text-start">
																					<label htmlFor="mailingAddress.city" className="form-label">
																						City
																					</label>

																					<Field
																						type="text"
																						id="mailingAddress.city"
																						name="mailingAddress.city"
																						className="form-control"
																						disabled={formik.values.sameAddress}
																					/>
																				</FormGroupWithError>
																			</div>

																			<div className="col">
																				<FormGroupWithError name="mailingAddress.postcode" className="text-start">
																					<label htmlFor="mailingAddress.postcode" className="form-label">
																						Postcode
																					</label>

																					<Field
																						type="text"
																						id="mailingAddress.postcode"
																						name="mailingAddress.postcode"
																						className="form-control"
																						disabled={formik.values.sameAddress}
																					/>
																				</FormGroupWithError>
																			</div>
																		</div>

																		<div className="row">
																			<div className="col">
																				<FormGroupWithError name="mailingAddress.countryName" className="text-start">
																					<label htmlFor="mailingAddress.countryName" className="form-label">
																						Country
																					</label>

																					<select
																						id="mailingAddress.countryName"
																						className="form-select"
																						disabled={formik.values.sameAddress}
																						onChange={(e) => {
																							formik.setFieldValue(
																								'mailingAddress.countryName',
																								countryList.find((country) => country.id === e.target.value)?.name
																							);

																							formik.setFieldValue('mailingAddress.countryId', e.target.value);
																						}}
																					>
																						<option value="">
																							{formik.values.mailingAddress.countryName || 'Select'}
																						</option>

																						{countryList?.length > 0 &&
																							countryList.map((country, index) => {
																								return (
																									<option key={country.id} value={country.id}>
																										{country.name}
																									</option>
																								);
																							})}
																					</select>
																				</FormGroupWithError>
																			</div>

																			<div className="col">
																				<FormGroupWithError
																					name="mailingAddress.stateProvinceName"
																					className="text-start"
																				>
																					<label htmlFor="mailingAddress.stateProvinceName" className="form-label">
																						State/Province
																					</label>

																					<select
																						id="mailingAddress.stateProvinceName"
																						className="form-select"
																						disabled={
																							!formik.values.mailingAddress.countryName || formik.values.sameAddress
																						}
																						onChange={(e) => {
																							formik.setFieldValue(
																								'mailingAddress.stateProvinceName',
																								stateMailingList.find((state) => state.id === e.target.value)?.name
																							);

																							formik.setFieldValue('mailingAddress.stateProvinceId', e.target.value);
																						}}
																					>
																						<option value="">
																							{formik.values.mailingAddress.stateProvinceName || 'Select'}
																						</option>

																						{stateMailingList?.map((state) => {
																							return (
																								<option key={state.id} value={state.id}>
																									{state.name}
																								</option>
																							);
																						})}
																					</select>
																				</FormGroupWithError>
																			</div>
																		</div>

																		<FormGroupWithError
																			name="subscriptionType"
																			className="text-start payment-method-wrapper"
																		>
																			<label htmlFor="subscriptionType" className="form-label">
																				Payment Method
																			</label>

																			<div className="payment-method-box">
																				<div
																					className={`radio-row-wrapper ${
																						formik.values.subscriptionType === OrganisationSubscriptionType.Paid
																							? 'active'
																							: ''
																					}`}
																				>
																					<div className="radio-row">
																						<Field
																							className="form-check-input"
																							name="subscriptionType"
																							id="subscriptionTypePaid"
																							type="radio"
																							value={OrganisationSubscriptionType.Paid}
																							checked={
																								formik.values.subscriptionType === OrganisationSubscriptionType.Paid
																							}
																							onChange={() =>
																								formik.setFieldValue(
																									'subscriptionType',
																									OrganisationSubscriptionType.Paid
																								)
																							}
																						/>

																						<label className="radio-option" htmlFor="subscriptionTypePaid">
																							Credit Card
																						</label>
																					</div>

																					{formik.values.subscriptionType === OrganisationSubscriptionType.Paid && (
																						<div className="info-text">
																							<p>
																								You will be redirected to Stripe in order to complete your payment
																								details.
																							</p>
																						</div>
																					)}
																				</div>

																				<div
																					className={`radio-row-wrapper ${
																						formik.values.subscriptionType === OrganisationSubscriptionType.Invoiced
																							? 'active'
																							: ''
																					}`}
																				>
																					<div className="radio-row">
																						<Field
																							name="subscriptionType"
																							className="form-check-input"
																							id="subscriptionTypeInvoiced"
																							type="radio"
																							value={OrganisationSubscriptionType.Invoiced}
																							checked={
																								formik.values.subscriptionType === OrganisationSubscriptionType.Invoiced
																							}
																							onChange={() =>
																								formik.setFieldValue(
																									'subscriptionType',
																									OrganisationSubscriptionType.Invoiced
																								)
																							}
																						/>

																						<label className="radio-option" htmlFor="subscriptionTypeInvoiced">
																							School Invoice
																						</label>
																					</div>

																					{formik.values.subscriptionType === OrganisationSubscriptionType.Invoiced && (
																						<>
																							<div className="info-text">
																								<p>This option is for schools who wish to pay with an invoice.</p>

																								<p>
																									Please note: Schools in NSW must enter a PO number at checkout if they
																									wish to pay by school invoice.
																								</p>

																								<p>
																									<strong>
																										The invoice will be sent to the organisation email address provided
																										above.
																									</strong>
																								</p>
																							</div>

																							<FormGroupWithError name="orderNumber" className="text-start mb-0">
																								<label htmlFor="orderNumber" className="form-label">
																									<span>PO Number</span>
																									<span className="text-shades-500">Optional</span>
																								</label>

																								<Field
																									type="text"
																									id="orderNumber"
																									name="orderNumber"
																									className="form-control"
																								/>
																							</FormGroupWithError>
																						</>
																					)}
																				</div>
																			</div>
																		</FormGroupWithError>

																		{/* PROMO CODE BOX */}
																		<div className="form-group">
																			<label htmlFor="promoCode" className="form-label">
																				Promo Code
																			</label>

																			{isPromoCodeValid ? (
																				<div className="promo-code-box-success">
																					<div className="success-info">
																						<SvgMask
																							path="/svg/check-success.svg"
																							width={20}
																							height={20}
																							className="icon"
																						/>
																						<span className="text">
																							<strong>{promoCode}</strong> applied
																						</span>
																					</div>

																					<button className="btn btn-remove" onClick={promoCodeRemoveHandler}>
																						Remove
																					</button>
																				</div>
																			) : (
																				<div className="promo-code-box">
																					<input
																						id="promoCode"
																						type="text"
																						value={promoCode}
																						onChange={(e: ChangeEvent<HTMLInputElement>) =>
																							setPromoCode(e.target.value)
																						}
																					/>

																					<button
																						className="btn btn-primary btn-sm btn-promo"
																						onClick={promoCodeHandler}
																					>
																						Add
																					</button>
																				</div>
																			)}

																			{showPromoCodeInvalidMessage ? (
																				<div className="text-danger text-new-line-wrap body-small fw-semibold mt-2">
																					Promo Code is invalid.
																				</div>
																			) : null}
																		</div>
																	</div>
																</section>
															</div>

															{formik.values.subscriptionType ? (
																<div className="summary-box pb-0">
																	<h2 className="h1 mb-0 fw-bold title">Summary</h2>

																	{formik.values.subscriptionType === OrganisationSubscriptionType.Invoiced &&
																	formik.values.email ? (
																		<div className="email-notification-box">
																			<SvgMask path="/svg/email.svg" width={24} height={24} className="icon" />

																			<p className="text">
																				An email with the invoice will be sent to <strong>{formik.values.email}</strong>{' '}
																				providing details on how to pay.
																			</p>
																		</div>
																	) : null}

																	<div className="info-row">
																		<div className="row-left">
																			<div className=" d-flex justify-content-start">
																				<h3 className="mb-0 fw-bold">{planSelectedDetails.name}</h3>

																				<span className="plan-text d-none d-md-inline-block">
																					{planSelectedDetails.allocations} users
																				</span>
																			</div>

																			{promoCode && isPromoCodeValid ? (
																				<div className="promo-info d-flex justify-content-start align-items-center">
																					<SvgMask path="/svg/promo.svg" width={24} height={24} className="icon" />

																					<p className="mb-0">{promoCode}</p>
																				</div>
																			) : null}
																		</div>

																		<div className="row-right text-end">
																			<h3 className="mb-0 fw-bold">
																				${Number(summaryDetails.originalAmount).toFixed(2)}
																			</h3>

																			{isPromoCodeValid && summaryDetails.discount ? (
																				<span className="total-text">
																					-${Number(summaryDetails.discount).toFixed(2)}
																				</span>
																			) : null}
																		</div>
																	</div>

																	<div className="info-row">
																		<div className="row-left">
																			<h3 className="mb-0 fw-bold">Total</h3>
																		</div>
																		<div className="row-right text-end">
																			<h3 className="mb-0 fw-bold">AUD ${Number(summaryDetails.amount).toFixed(2)}</h3>

																			<span className="total-text">
																				GST Incl. ${Number(summaryDetails.gst).toFixed(2)}
																			</span>
																		</div>
																	</div>

																	<div className="info-row flex-column flex-md-row align-items-md-start pb-md-0">
																		<div className="row-left check-box">
																			<div className="form-group">
																				<label className="form-control-checkbox">
																					<input
																						type="checkbox"
																						checked={confirmTerms}
																						onChange={() => setConfirmTerms(!confirmTerms)}
																					/>

																					<span>
																						I confirm that I have reviewed the selected plan and am ready for purchase.
																						I agree to the{' '}
																						<a
																							href="https://www.littlelearnersloveliteracy.com.au/pages/llllonline-terms-and-conditions"
																							target="_blank"
																							rel="noreferrer"
																						>
																							Terms and Conditions
																						</a>{' '}
																						and the{' '}
																						<a
																							href="https://www.littlelearnersloveliteracy.com.au/policies/privacy-policy"
																							target="_blank"
																							rel="noreferrer"
																						>
																							Privacy Policy.
																						</a>
																					</span>
																				</label>
																			</div>
																		</div>

																		<div className="row-right button-container">
																			<button
																				type="submit"
																				className="btn btn-lg w-100 w-md-auto"
																				disabled={!confirmTerms || subscriptionFormSubmitting}
																			>
																				Confirm Purchase
																				{subscriptionFormSubmitting ? (
																					<Spinner animation="border" size="sm" />
																				) : (
																					<SvgMask
																						path="/svg/chevron-right.svg"
																						width={16}
																						height={16}
																						className="icon"
																					/>
																				)}
																			</button>
																		</div>
																	</div>

																	{formik.values.subscriptionType === OrganisationSubscriptionType.Paid ? (
																		<div className="info-row justify-content-center">
																			<SvgMask
																				path="/svg/icon-stripe-power.svg"
																				width={150}
																				height={34}
																				className="stripe-icon"
																			/>
																		</div>
																	) : null}
																</div>
															) : null}
														</>
													) : null}
												</FormikForm>
											);
										}}
									</Formik>
								)}

								{formMode === FormModeTypes.Trial && (
									<Formik
										initialValues={formValuesTrial}
										onSubmit={onSubmitTrial}
										validationSchema={validationSchemaTrial}
										enableReinitialize
										validateOnChange
										validateOnBlur
									>
										<FormikForm onChange={handleChangeTrial}>
											<div className="trial-card-wrapper">
												<article className="trial-card">
													<div className="card-name">Little Learners Love Literacy Online Trial</div>

													<div className="card-content">
														<div className="price">
															<h1 className="m-0 text-shades-800">
																<strong>Free</strong>
															</h1>

															<span className="note">for 7 days</span>
														</div>

														<p className="access">Limited Access</p>

														<ul className="details">
															<li>
																<SvgMask path="/svg/check.svg" width={20} height={20} className="icon" />
																Limited access to our online platform and eLibrary for teachers and students.
															</li>

															<li>
																<SvgMask path="/svg/check.svg" width={20} height={20} className="icon" />
																Sample weekly plans based on the Little Learners teaching sequence.
															</li>

															<li>
																<SvgMask path="/svg/check.svg" width={20} height={20} className="icon" />
																Helpful resources and teaching routines for class activities.
															</li>
														</ul>
													</div>
												</article>
											</div>

											<div className="heading-section">
												<h2 className="h1 form-heading">Let's get some details</h2>
												<p className="form-subheading">Enter your name and email address</p>
											</div>

											<FormCustomError className="text-center my-2" />
											<FormCustomError label="captchaToken" className="text-center my-2" />

											<div className="row">
												<div className="col-md-6">
													<FormGroupWithError name="firstName" className="text-start">
														<label htmlFor="firstName" className="form-label">
															First Name
														</label>

														<Field type="text" id="firstName" name="firstName" className="form-control" />
													</FormGroupWithError>
												</div>
												<div className="col-md-6">
													<FormGroupWithError name="lastName" className="text-start">
														<label htmlFor="lastName" className="form-label">
															Last Name
														</label>

														<Field type="text" id="lastName" name="lastName" className="form-control" />
													</FormGroupWithError>
												</div>
											</div>

											<FormGroupWithError name="email" className="text-start">
												<label htmlFor="email" className="form-label">
													Email Address
												</label>

												<Field type="email" id="email" name="email" className="form-control" />
											</FormGroupWithError>

											<FormGroupWithError name="confirmEmail" className="text-start">
												<label htmlFor="confirmEmail" className="form-label">
													Confirm Email Address
												</label>

												<Field type="email" id="confirmEmail" name="confirmEmail" className="form-control" />
											</FormGroupWithError>

											<FormGroupWithError name="organisation" className="text-start">
												<label htmlFor="organisation" className="form-label">
													Organisation
												</label>

												<Field type="text" id="organisation" name="organisation" className="form-control" />
											</FormGroupWithError>

											<div className="d-grid d-md-block mt-4-5 mb-4-5 text-center">
												<button type="submit" className="btn btn-lg px-5" disabled={trialFormSubmitting}>
													Sign up for trial
													{trialFormSubmitting ? (
														<Spinner animation="border" size="sm" />
													) : (
														<SvgMask path="/svg/chevron-right.svg" width={16} height={16} />
													)}
												</button>
											</div>
										</FormikForm>
									</Formik>
								)}
							</div>
						</div>
					)}
				</div>

				<ScrollToTopButton />
			</div>
		</main>
	);
};

export default BuyersJourneyRegister;
