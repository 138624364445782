import { FC, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/slice';
import { UserSlice, UserType } from '../../store/slice/User';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';

// COMPONENTS
import TitleSection from '../../components/TitleSection/TitleSection';
import SvgMask from '../../components/_Helpers/SvgMask';
import Divider from '../../components/Divider/Divider';
import FileStudentImport from '../../components/FileImport/FileStudentImport';

// MODELS
import { ClassViewStudent, StudentAdminCreateAccountStatus, StudentAdminCreateStudent } from '../../api/models';

// PACKAGES
import { Modal, Spinner } from 'react-bootstrap';
import { Field, Formik, FormikHelpers, FormikProps } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';

// UTILS
import apibridge from '../../apibridge';
import { guid } from '../../libs/utils';
import DelayedFadeIn from '../../components/_Helpers/DelayedFadeIn';

export type AddStudentsFormValuesType = {
	firstName: string;
	lastName: string;
	students: StudentAdminCreateStudent[];
	existingStudents: ClassViewStudent[];
};

const AddStudents: FC = () => {
	const [existingStudents, setExistingStudents] = useState<ClassViewStudent[]>([]);
	const [name, setName] = useState('');
	const [showAssignTeacherModal, setShowAssignTeacherModal] = useState(false);
	const [invitesLeft, setInvitesLeft] = useState(0); // server side invites left
	const [loading, setLoading] = useState(true);

	const [submittingStudentImport, setSubmittingStudentImport] = useState(false);
	const [submittingStudents, setSubmittingStudents] = useState(false);
	const disableSubmitButton = submittingStudentImport || submittingStudents;

	const formikRef = useRef<FormikProps<AddStudentsFormValuesType>>(null);

	const location = useLocation();
	const { pathname, state } = location;
	const splitPath = pathname.split('/');
	const classId = decodeURIComponent(splitPath[splitPath.length - 1]);
	const user = useAppSelector((state) => state.user);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const getStudentList = async () => {
		const response = await apibridge.getClassView(classId);
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				setExistingStudents(response.data.result.students || []);
				setName(response.data.result.name || '');
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Student list error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}
	};

	const getInvitesLeft = async () => {
		const response = await apibridge.getInvitesLeft();
		if (response && response.data && !response.data.isError && response.data.result) {
			const { invitesLeft = 0, isUnilimited } = response.data.result;
			setInvitesLeft(isUnilimited ? Infinity : invitesLeft);
		}
	};

	const getData = async () => {
		await Promise.all([getInvitesLeft(), getStudentList()]);
		setLoading(false);
	};

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const initialValues: AddStudentsFormValuesType = {
		existingStudents: existingStudents,
		students: [],
		firstName: '',
		lastName: ''
	};

	const removeStudent = async (
		student: StudentAdminCreateStudent,
		values: AddStudentsFormValuesType,
		setFieldValue: FormikHelpers<AddStudentsFormValuesType>['setFieldValue']
	) => {
		const updatedStudents = values.students.filter((s) => s !== student);
		setFieldValue('students', updatedStudents);
	};

	const submitStudent = async (
		values: AddStudentsFormValuesType,
		formikBag: FormikHelpers<AddStudentsFormValuesType>
	) => {
		const newStudent: StudentAdminCreateStudent = {
			firstName: values.firstName.trim(),
			lastName: values.lastName.trim()
		};

		if (existingStudents.length > 0) {
			const nameExists = existingStudents.some(
				(student) => student.firstName === values.firstName && student.lastName === values.lastName
			);

			if (nameExists) {
				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'danger',
						heading: `Cannot add student that already exists`,
						description:
							'That student already exists in this class. Please add new student names to successfully add them to the class.'
					})
				);
				return;
			}
		}

		if (values.students.length > 0) {
			const nameExists = values.students.some(
				(student) => student.firstName === values.firstName && student.lastName === values.lastName
			);

			if (nameExists) {
				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'danger',
						heading: `Cannot add student that already exists`,
						description:
							'That student already exists in this class. Please add new student names to successfully add them to the class.'
					})
				);
				return;
			}
		}

		const updatedStudents = [...values.students, newStudent];
		const updatedCurrentInvitesLeft = invitesLeft - updatedStudents.length;

		formikBag.setFieldValue('students', updatedStudents);
		formikBag.setFieldValue('firstName', '');
		formikBag.setFieldValue('lastName', '');

		if (updatedCurrentInvitesLeft < 0) {
			dispatch(
				ToastMessagesSlice.actions.add({
					id: guid(),
					type: 'danger',
					heading: `Exceeded user limit`,
					description: 'Please remove excess users from the student list to proceed.'
				})
			);
		}
	};

	const submitStudentsToAPI = async (values: AddStudentsFormValuesType) => {
		setSubmittingStudents(true);

		const response = await apibridge.postStudentAdminCreate({ classId: classId, students: values.students });
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				const { accounts = [] } = response.data.result;
				const hasDuplicateStatus = accounts.some((student) => student.status === 'Duplicate');
				const hasInvalidStatus = accounts.some((student) => student.status === 'Invalid');

				if (hasDuplicateStatus) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Cannot add students that already exist',
							description:
								'Some students in the list already exist in this class. Please add new student names to successfully add them to the class.'
						})
					);
				} else if (hasInvalidStatus) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Cannot add students with invalid characters in their names',
							description:
								'Some of these students may contain names with invalid characters. Please remove these characters to add them to the class.'
						})
					);
				} else {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'success',
							heading: `New ${accounts.length === 1 ? 'student' : 'students'} added to ${name}`,
							description: ''
						})
					);
					// also getting updated User object after so can determine whether to show the 'Add Students' dot anymore
					if (!user.profiles?.[0].studentsAdded) {
						dispatch(UserSlice.actions.forceUpdate());
					}
					navigate(`/student-management/class/${classId}`);
				}
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Student submission error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setSubmittingStudents(false);
	};

	const assignTeacherToClassAPI = async () => {
		const response = await apibridge.postStaffClassAssign({ classId, profileId: user.activeProfileId });
		if (response && response.data && response.data.validationErrors) {
			for (const err of response.data.validationErrors) {
				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'danger',
						heading: 'Teacher assignment error',
						description: err.reason || 'Unknown error'
					})
				);
			}
		}
	};

	const submitStudents = async (values: AddStudentsFormValuesType) => {
		if (existingStudents.length > 0) {
			submitStudentsToAPI(values);
		} else {
			setShowAssignTeacherModal(true);
		}
	};

	const assignTeacherToClass = async (values: AddStudentsFormValuesType) => {
		await assignTeacherToClassAPI();
		await submitStudentsToAPI(values); // will redirect after
	};

	return (
		<div className="add-student-page d-flex flex-column flex-grow-1">
			<TitleSection
				backToText={state?.newClass ? 'Back to Add Class' : 'Back to class'}
				backToUrl={state?.newClass ? '/student-management/add-class' : `/student-management/class/${classId}`}
				title={`Add students to ${name}`}
			></TitleSection>

			{loading ? (
				<div className="d-flex align-items-center justify-content-center">
					<DelayedFadeIn>
						<Spinner animation="border" role="status">
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					</DelayedFadeIn>
				</div>
			) : (
				<section className="container d-flex flex-column flex-grow-1">
					<Formik enableReinitialize initialValues={initialValues} onSubmit={submitStudent} innerRef={formikRef}>
						{(formik) => {
							const { values, handleSubmit, isSubmitting, setFieldValue } = formik;
							const currentInvitesLeft = invitesLeft - values.students.length;

							return (
								<>
									<div className="row row-gap-5 flex-grow-1">
										<div className="col d-flex flex-column justify-content-between gap-3">
											<div>
												<form onSubmit={handleSubmit} className="d-flex gap-4 flex-grow-1 align-items-end">
													<div className="form-group flex-grow-1">
														<label htmlFor="first-name" className="body-tiny mb-1">
															<strong>First name</strong>
														</label>
														<Field id="first-name" name="firstName" className="form-control" placeholder="E.g. John" />
													</div>
													<div className="form-group flex-grow-1">
														<label htmlFor="last-name" className="body-tiny mb-1">
															<strong>Last name</strong>
														</label>
														<Field id="last-name" name="lastName" className="form-control" />
													</div>
													<button
														disabled={isSubmitting || values.firstName === '' || values.lastName === ''}
														type="submit"
														className="btn"
													>
														Add
														<SvgMask path="/svg/plus.svg" width={16} height={16} />
													</button>
												</form>

												<Divider />
												<FileStudentImport
													classId={classId}
													setInvitesLeft={setInvitesLeft}
													formik={formik}
													submittingStudentImport={submittingStudentImport}
													setSubmittingStudentImport={setSubmittingStudentImport}
												/>
											</div>
											<button
												type="button"
												disabled={values.students.length === 0 || currentInvitesLeft < 0 || disableSubmitButton}
												onClick={() => submitStudents(values)}
												className="btn btn-lg w-100"
											>
												Add students to class {submittingStudents && <Spinner animation="border" size="sm" />}
											</button>
										</div>
										<div className="col-lg-4 offset-lg-1 d-flex flex-column">
											<div className="student-list d-flex flex-column flex-grow-1">
												<div className="header">
													<h4 className="mb-0">Student list</h4>
													{currentInvitesLeft !== Infinity && (
														<p
															className={`mb-0 body-small ${
																currentInvitesLeft < 0 ? 'text-danger' : 'text-shades-500'
															}`}
														>
															{currentInvitesLeft >= 0 ? (
																<strong>
																	{currentInvitesLeft} {currentInvitesLeft === 1 ? 'seat' : 'seats'} left
																</strong>
															) : (
																<strong>
																	{Math.abs(currentInvitesLeft)} {Math.abs(currentInvitesLeft) === 1 ? 'user' : 'users'}{' '}
																	over limit
																</strong>
															)}
														</p>
													)}
												</div>
												<div className="content position-relative d-flex flex-column justify-content-start flex-grow-1">
													<div className="position-absolute top-0 left-0 w-100 h-100">
														{values?.students?.length ? (
															values.students?.map((student, index) => (
																<div
																	key={index}
																	className="class-item d-flex align-items-center justify-content-start gap-4 w-100 p-2"
																>
																	<div className="icon-wrapper">
																		<SvgMask path="/svg/Students-Smile.svg" width={24} height={24} />
																	</div>
																	<p className="mb-0 flex-grow-1">
																		{/* including &nbsp; so names accidentally added with extra spaces at the start/end of the name will show up in the UX */}
																		<strong>
																			<span>{student.firstName}</span>&nbsp;<span>{student.lastName}</span>
																		</strong>
																	</p>
																	<button
																		onClick={() => removeStudent(student, values, setFieldValue)}
																		className="btn btn-icon btn-icon-white"
																	>
																		<SvgMask path="/svg/cross-thin.svg" width={24} height={24} />
																	</button>
																</div>
															))
														) : (
															<div
																className={`d-flex flex-column align-items-center mt-4 justify-content-center gap-3 text-shades-500 text-center p-3 ${
																	values.existingStudents?.length ? '' : 'flex-grow-1'
																}`}
															>
																<div className="no-students-icon">
																	<SvgMask path="/svg/students-plural.svg" width={24} height={24} />
																</div>
																<p>Added students will appear here</p>
															</div>
														)}
														{values.existingStudents?.length ? (
															<>
																<p className="sub-title">
																	<strong>Existing students in current class</strong>
																</p>
																{values.existingStudents?.map((student, index) => (
																	<div
																		key={index}
																		className="class-item d-flex align-items-center justify-content-start gap-4 w-100 p-2"
																	>
																		<div className="icon-wrapper">
																			<SvgMask path="/svg/Students-Smile.svg" width={24} height={24} />
																		</div>
																		<p className="mb-0 flex-grow-1">
																			<strong>
																				{student.firstName} {student.lastName}
																			</strong>
																		</p>
																	</div>
																))}
															</>
														) : null}
													</div>
												</div>
											</div>
										</div>
									</div>

									{/* Add students and assign teacher modal */}
									<Modal show={showAssignTeacherModal} onHide={() => setShowAssignTeacherModal(false)} centered>
										<Modal.Body>
											<button
												type="button"
												className="btn-close close"
												aria-label="Close"
												onClick={() => setShowAssignTeacherModal(false)}
											></button>
											<h2 className="m-0 text-shades-800">
												<strong>Assign yourself as the teacher?</strong>
											</h2>
											<p className="m-0">Add students and assign this class to yourself as the teacher?</p>
											<div className="button-group d-flex">
												<button
													onClick={() => submitStudentsToAPI(values)}
													className="btn btn-lg btn-white w-50"
													disabled={disableSubmitButton}
												>
													Leave unassigned
												</button>
												<button
													onClick={() => assignTeacherToClass(values)}
													className="btn btn-lg w-50"
													disabled={disableSubmitButton}
												>
													Assign to myself
												</button>
											</div>
										</Modal.Body>
									</Modal>
								</>
							);
						}}
					</Formik>
				</section>
			)}
		</div>
	);
};

export default AddStudents;
