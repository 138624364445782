import { FC, useEffect, useState, Dispatch as ReactDispatch, SetStateAction } from 'react';

// PACKAGES
import { Fade } from 'react-bootstrap';

// COMPONENTS
import QuizIntro from '../../components/Quiz/QuizIntro';
import QuizIntroText from '../../components/Quiz/QuizIntroText';
import SvgMask from '../../components/_Helpers/SvgMask';
import QuizSubmit from '../../components/Quiz/QuizSubmit';
import {
	QuizStateType,
	RandomisedBookViewSelectRightAnswerOption
} from '../../components/_Layout/LayoutQuiz/LayoutQuiz';
import QuizNavigation from '../../components/Quiz/QuizNavigation';

// UTILS
import { guid, shuffleArray } from '../../libs/utils';
import { useAudioPlayer } from '../../libs/customHooks';
import ImgRem from '../../libs/imgRem';
import CroppedImage from '../../components/_Helpers/CroppedImage';

// TYPES
import {
	StaffBookViewActivity,
	StaffBookViewSelectRightAnswer,
	StudentBookViewActivity,
	StudentBookViewSelectRightAnswer
} from '../../api/models';

const AnswerBox: FC<{
	activity: StaffBookViewSelectRightAnswer | StudentBookViewSelectRightAnswer;
	updatedAnswerText: string;
}> = ({ activity, updatedAnswerText }) => {
	const { answerAudioUrl, answerImageUrl = '' } = activity;

	const audioPlayer = useAudioPlayer();
	const { addToAudioQueue, clearAudioQueue, getCurrentAudioClip } = audioPlayer;
	const currentAudioClip = getCurrentAudioClip();

	const audioPlaying = answerAudioUrl && currentAudioClip?.audioProps.url === answerAudioUrl;
	const isText = updatedAnswerText;
	const hasAudioImage = answerImageUrl !== '';

	return (
		<div
			className={`position-relative ${isText ? 'answer-text-box' : 'answer-image-box'} ${
				!isText && (hasAudioImage ? '' : 'show-placeholder')
			} ${audioPlaying ? 'audio-playing' : ''}`}
		>
			{answerAudioUrl && (
				<button
					type="button"
					className="btn btn-icon btn-audio-icon position-absolute"
					aria-label="Play answer text audio"
					onClick={() => {
						if (getCurrentAudioClip()?.audioProps.url === answerAudioUrl) {
							clearAudioQueue();
						} else {
							clearAudioQueue();
							addToAudioQueue({
								id: guid(),
								url: answerAudioUrl
							});
						}
					}}
				>
					<i></i>
				</button>
			)}
			{updatedAnswerText ? (
				<h1
					className="text-answer display text-shades-800 m-0 user-select-none"
					dangerouslySetInnerHTML={{ __html: updatedAnswerText }}
				></h1>
			) : answerImageUrl ? (
				<CroppedImage src={answerImageUrl} className="object-fit-scale" width={200} height={200} alt="" />
			) : (
				<div className="placeholder d-flex align-items-center justify-content-center text-shades-500">
					<SvgMask path="/svg/image.svg" width={96} height={96} />
				</div>
			)}
		</div>
	);
};

const AnswerButton: FC<{
	option: RandomisedBookViewSelectRightAnswerOption;
	hasReachedAttemptLimit: boolean;
	selectedAnswer: RandomisedBookViewSelectRightAnswerOption | undefined;
	setSelectedAnswer: ReactDispatch<SetStateAction<RandomisedBookViewSelectRightAnswerOption | undefined>>;
	quizState: QuizStateType;
	setQuizState: ReactDispatch<SetStateAction<QuizStateType>>;
}> = ({ option, hasReachedAttemptLimit, selectedAnswer, setSelectedAnswer, quizState, setQuizState }) => {
	const { audioUrl, imageUrl, isRightAnswer, text = '' } = option;

	const audioPlayer = useAudioPlayer();
	const { addToAudioQueue, clearAudioQueue, getCurrentAudioClip } = audioPlayer;

	const audioPlaying = audioUrl && getCurrentAudioClip()?.audioProps.url === audioUrl;
	const buttonIsSelected = selectedAnswer?.id === option.id;

	const hasSubmitted = quizState !== 'unsubmitted';
	const showIsRightAnswer = hasSubmitted && isRightAnswer && (buttonIsSelected || hasReachedAttemptLimit);
	const showIsWrongAnswer = hasSubmitted && !isRightAnswer && buttonIsSelected;

	return (
		<button
			type="button"
			className={`
				position-relative btn btn-option 
				${audioPlaying ? 'audio-playing' : ''}
				${(buttonIsSelected && !hasSubmitted) || (hasSubmitted && hasReachedAttemptLimit && isRightAnswer) ? 'active' : ''}
				${showIsRightAnswer ? 'correct' : ''}
				${showIsWrongAnswer ? 'error pe-none' : ''}
				${text ? 'text-button' : 'image-button'}
				${(hasSubmitted && hasReachedAttemptLimit) || ['showHappyFace', 'canProceed'].includes(quizState) ? 'pe-none' : ''}
			`}
			onClick={() => {
				setQuizState('unsubmitted');
				setSelectedAnswer(option);

				if (audioUrl) {
					if (audioPlaying) {
						clearAudioQueue();
					} else {
						clearAudioQueue();
						addToAudioQueue({
							id: guid(),
							url: audioUrl
						});
					}
				}
			}}
		>
			{hasSubmitted && (
				<div className="position-absolute top-0 translate-middle-y">
					{showIsRightAnswer && (
						<div className="circle-correct">
							<i></i>
						</div>
					)}
					{showIsWrongAnswer && (
						<div className="circle-incorrect">
							<i></i>
						</div>
					)}
				</div>
			)}
			{text ? (
				<span className="h1 m-0">
					<strong>{text}</strong>
				</span>
			) : (
				<ImgRem src={imageUrl} width={120} height={120} alt="" />
			)}
		</button>
	);
};

const QuizSelectRightAnswer: FC<{
	activityData: StaffBookViewActivity | StudentBookViewActivity;
	totalBookPages: number;
	bookId: string;
	questionNumber: string;
	totalQuestions: number;
}> = ({ activityData, totalBookPages, bookId, questionNumber, totalQuestions }) => {
	const { selectRightAnswer = {}, status = '' } = activityData;
	const attempts = 'attempts' in activityData ? activityData.attempts || 0 : 0;
	const maxActivityAttempts = 'maxActivityAttempts' in activityData ? activityData.maxActivityAttempts || 2 : 2;
	const { answerText = '' } = selectRightAnswer;

	const [isMounted, setIsMounted] = useState(false);
	const [showIntro, setShowIntro] = useState(questionNumber === '1');
	const [showQuiz, setShowQuiz] = useState(!showIntro);
	const [quizAttempts, setQuizAttempts] = useState(attempts);
	const [hasReachedAttemptLimit, setHasReachedAttemptLimit] = useState(false);

	const [updatedAnswerText, setUpdatedAnswerText] = useState('');
	const [selectedAnswer, setSelectedAnswer] = useState<RandomisedBookViewSelectRightAnswerOption>();

	const [quizState, setQuizState] = useState<QuizStateType>('unsubmitted');

	const [randomisedOptions, setRandomisedOptions] = useState<RandomisedBookViewSelectRightAnswerOption[]>([]); // for the result of the shuffled items

	const answerUnderscoresRegex = /([_])\1+/;
	const quizAlreadyCompleted = ['Successful', 'Unsuccessful'].includes(status);

	const handleReset = () => setSelectedAnswer(undefined);

	useEffect(() => {
		if (isMounted) {
			// shuffle the options
			const randomisedOptions: RandomisedBookViewSelectRightAnswerOption[] = [];
			activityData.selectRightAnswer?.options?.forEach((option) => {
				randomisedOptions.push({
					...option,
					randomisedText: '',
					hoveredAnswerText: '',
					selectedAnswerText: ''
				});
			});

			let answerUnderscoreMatchText = answerText.match(answerUnderscoresRegex)?.[0];
			// update the text from underscores to another character because iPad is a moron and won't render text-decoration: underline; on them
			if (answerUnderscoreMatchText) {
				answerUnderscoreMatchText = answerUnderscoreMatchText.replace(/_/g, 'e'); // 'e' seems to have a more natural character width than '-'
			}

			// intercept the text and wrap the underscore _ in the text with a <span> so it can match the design (am assuming text will only ever contain one set of underscores)
			setUpdatedAnswerText(
				answerText.replace(
					answerUnderscoresRegex,
					`<span class="answer-underscores">${answerUnderscoreMatchText}</span>`
				)
			);
			setRandomisedOptions(shuffleArray(randomisedOptions));
		}
	}, [isMounted]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setIsMounted(true);
	}, []);

	if (!selectRightAnswer) return null;

	const { question = '', questionAudioUrl = '' } = selectRightAnswer;
	const allAnswersSelected = selectedAnswer !== undefined;
	const allAnswersCorrect = !!selectedAnswer?.isRightAnswer;

	return (
		<div className={`quiz-select-right-answer d-flex flex-grow-1 ${showQuiz ? '' : 'intro-is-showing'}`}>
			<QuizIntro
				showIntro={showIntro}
				setShowIntro={setShowIntro}
				setShowQuiz={setShowQuiz}
				questionNumber={questionNumber}
				totalQuestions={totalQuestions}
			/>
			<Fade in={showQuiz} mountOnEnter>
				<div className="position-relative d-flex flex-column justify-content-center align-items-center gap-4 flex-grow-1 text-center py-4">
					<div className="container container-large-gutters">
						<div className="row w-100">
							<div className="col-xl-8 offset-xl-2">
								<p className="text-shades-500 m-4">
									<strong>
										{questionNumber} / {totalQuestions}
									</strong>
								</p>
								<QuizIntroText questionAudioUrl={questionAudioUrl} questionText={question} />
							</div>
						</div>
					</div>
					<div className="container d-flex flex-column flex-grow-1 gap-5">
						<div className="d-flex flex-column justify-content-center flex-grow-1">
							<div className="row">
								<div className="col-xl-8 offset-xl-2">
									<div className="question-box-wrapper d-flex flex-column align-items-center justify-content-center">
										<AnswerBox activity={selectRightAnswer} updatedAnswerText={updatedAnswerText} />
										<div className="d-flex gap-4">
											{randomisedOptions.map((option) => (
												<AnswerButton
													key={option.id}
													option={option}
													hasReachedAttemptLimit={hasReachedAttemptLimit}
													selectedAnswer={selectedAnswer}
													setSelectedAnswer={setSelectedAnswer}
													quizState={quizState}
													setQuizState={setQuizState}
												/>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
						<QuizSubmit
							hasReachedAttemptLimit={hasReachedAttemptLimit}
							quizAlreadyCompleted={quizAlreadyCompleted}
							questionNumber={questionNumber}
							totalQuestions={totalQuestions}
							bookId={bookId}
							activityId={selectRightAnswer.id || ''}
							maxActivityAttempts={maxActivityAttempts}
							allAnswersSelected={allAnswersSelected}
							allAnswersCorrect={allAnswersCorrect}
							quizAttempts={quizAttempts}
							setQuizAttempts={setQuizAttempts}
							quizState={quizState}
							setQuizState={setQuizState}
							setHasReachedAttemptLimit={setHasReachedAttemptLimit}
							handleReset={handleReset}
						/>
					</div>
					<QuizNavigation
						questionNumber={parseInt(questionNumber)}
						totalQuestions={totalQuestions}
						totalBookPages={totalBookPages}
					/>
				</div>
			</Fade>
		</div>
	);
};

export default QuizSelectRightAnswer;
