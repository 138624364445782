import { FC, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';

// PACKAGES
import { Spinner } from 'react-bootstrap';

// COMPONENTS
import StudentHomeButton from '../ELibrary/Student/StudentHomeButton';
import { generateBookPageNumber } from '../../components/Book/Book';

// UTILS
import SvgMask from '../../components/_Helpers/SvgMask';
import { guid } from '../../libs/utils';
import DelayedFadeIn from '../../components/_Helpers/DelayedFadeIn';
import ImgRem from '../../libs/imgRem';

// TYPES
import apibridge from '../../apibridge';
import { BookRatingType, StudentBookViewBook } from '../../api/models';

const Feedback: FC = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [, , bookIdUrlPart] = location.pathname.split('/');

	const [isLoadingActivityData, setIsLoadingActivityData] = useState(false);
	const [bookData, setBookData] = useState<StudentBookViewBook>();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [selectedFeedback, setSelectedFeedback] = useState<BookRatingType>();

	const totalBookPages = bookData?.pages?.length || 0;

	// book data includes activity data
	const getBookData = async () => {
		setIsLoadingActivityData(true);

		const response = await apibridge.getStudentBookView(bookIdUrlPart);

		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				setBookData(response.data.result);
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Book error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setIsLoadingActivityData(false);
	};

	const handleSubmitFeedback = async () => {
		setIsSubmitting(true);

		const response = await apibridge.postStudentBookRating({
			bookId: decodeURIComponent(bookIdUrlPart),
			emotion: selectedFeedback
		});
		if (response && response.data) {
			if (!response.data.isError) {
				// if there are no quiz questions, then the quiz page will redirect to /complete page
				navigate('../quiz/1', { relative: 'path' });
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Rating error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setIsSubmitting(false);
	};

	useEffect(() => {
		if (selectedFeedback) handleSubmitFeedback();
	}, [selectedFeedback]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getBookData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="page-feedback d-flex flex-column flex-grow-1">
			<StudentHomeButton />
			{isLoadingActivityData ? (
				<div className="d-flex align-items-center justify-content-center flex-grow-1">
					<DelayedFadeIn>
						<Spinner animation="border" role="status">
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					</DelayedFadeIn>
				</div>
			) : (
				<div className="container d-flex justify-content-center flex-column flex-grow-1">
					<div className="content-wrapper d-flex flex-column justify-content-between flex-grow-1 gap-4 text-center">
						<div className="d-flex align-items-center justify-content-center flex-grow-1">
							<div className="d-flex flex-column align-items-center gap-4-5">
								<h1 className="display text-shades-800 m-0">Well done!</h1>
								<div className="main-content d-flex flex-column">
									<h2 className="h1 m-0">
										<strong>How did it go?</strong>
									</h2>
									<div className="d-flex justify-content-center gap-4-5">
										<button
											type="button"
											className={`btn btn-reset btn-feedback rounded-circle ${
												selectedFeedback === 'Happy' ? 'active pe-none' : ''
											}`}
											disabled={selectedFeedback && selectedFeedback !== 'Happy'}
											onClick={() => setSelectedFeedback('Happy')}
										>
											<ImgRem
												src="/img/feedback-happy.png"
												width="200"
												height="200"
												alt="Smiling child"
												className="feedback-image object-fit-cover rounded-circle"
											/>
										</button>
										<button
											type="button"
											className={`btn btn-reset btn-feedback rounded-circle ${
												selectedFeedback === 'Confused' ? 'active pe-none' : ''
											}`}
											disabled={selectedFeedback && selectedFeedback !== 'Confused'}
											onClick={() => setSelectedFeedback('Confused')}
										>
											<ImgRem
												src="/img/feedback-confused.png"
												width="200"
												height="200"
												alt="Confused child"
												className="feedback-image object-fit-cover rounded-circle"
											/>
										</button>
										<button
											type="button"
											className={`btn btn-reset btn-feedback rounded-circle ${
												selectedFeedback === 'Unhappy' ? 'active pe-none' : ''
											}`}
											disabled={selectedFeedback && selectedFeedback !== 'Unhappy'}
											onClick={() => setSelectedFeedback('Unhappy')}
										>
											<ImgRem
												src="/img/feedback-unhappy.png"
												width="200"
												height="200"
												alt="Unhappy child"
												className="feedback-image object-fit-cover rounded-circle"
											/>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					{totalBookPages && (
						<Link
							to={`..#${generateBookPageNumber(totalBookPages - 1, totalBookPages)}`}
							className="btn btn-quiz-bottom-left"
							reloadDocument
						>
							<SvgMask path="/svg/book-back.svg" width={48} height={48} />
							<span className="visually-hidden">Previous</span>
						</Link>
					)}
				</div>
			)}
		</div>
	);
};

export default Feedback;
