import { FC, useEffect, Dispatch as ReactDispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';
import { useAppSelector } from '../../store/slice';

// PACKAGES
import { Link } from 'react-router-dom';
import { Fade } from 'react-bootstrap';

// UTILS
import SvgMask from '../_Helpers/SvgMask';
import { guid } from '../../libs/utils';
import { quizQuestionRoutes } from './QuizNavigation';

// TYPES
import apibridge from '../../apibridge';
import { StudentActivityProgressCommand } from '../../api/models';
import { QuizStateType } from '../_Layout/LayoutQuiz/LayoutQuiz';

const QuizSubmit: FC<{
	hasReachedAttemptLimit: boolean;
	quizAlreadyCompleted: boolean;
	questionNumber: string;
	totalQuestions: number;
	bookId: string;
	activityId: string;
	allAnswersSelected: boolean;
	allAnswersCorrect: boolean;
	maxActivityAttempts: number;
	quizAttempts: number;
	setQuizAttempts: ReactDispatch<SetStateAction<number>>;
	quizState: QuizStateType;
	setQuizState: ReactDispatch<SetStateAction<QuizStateType>>;
	setHasReachedAttemptLimit: ReactDispatch<SetStateAction<boolean>>;
	handleReset: () => void;
}> = ({
	hasReachedAttemptLimit,
	quizAlreadyCompleted,
	questionNumber,
	totalQuestions,
	bookId,
	activityId,
	allAnswersSelected,
	allAnswersCorrect,
	maxActivityAttempts,
	quizAttempts,
	setQuizAttempts,
	quizState,
	setQuizState,
	setHasReachedAttemptLimit,
	handleReset
}) => {
	const dispatch = useDispatch();
	const systemInfo = useAppSelector((state) => state.systemInfo);

	const reachedAttemptLimit = quizAttempts >= maxActivityAttempts;
	const { nextQuestionRoute } = quizQuestionRoutes(parseInt(questionNumber), totalQuestions);

	const submitActivityProgress = async (values: StudentActivityProgressCommand) => {
		const { bookId, activityId, isSuccessful } = values;

		if (reachedAttemptLimit || quizAlreadyCompleted || systemInfo.type === 'staff') {
			setQuizState(allAnswersCorrect ? 'showHappyFace' : 'showSadFace');
		} else {
			// if not reached attempt limit, perform submission and increase attempt count by 1
			const response = await apibridge.postStudentActivityProgress({
				bookId,
				activityId,
				isSuccessful
			});

			if (response && response.data) {
				if (!response.data.validationErrors) {
					setQuizAttempts(quizAttempts + 1);
					setHasReachedAttemptLimit(reachedAttemptLimit);
					setQuizState(allAnswersCorrect ? 'showHappyFace' : 'showSadFace');
				} else {
					for (const err of response.data.validationErrors) {
						dispatch(
							ToastMessagesSlice.actions.add({
								id: guid(),
								type: 'danger',
								heading: 'Activity error',
								description: err.reason || 'Unknown error'
							})
						);
					}
				}
			}
		}
	};

	useEffect(() => {
		let timeoutId: NodeJS.Timeout;
		if ((quizState === 'showSadFace' && reachedAttemptLimit) || quizState === 'showHappyFace') {
			timeoutId = setTimeout(() => setQuizState('canProceed'), 2000);
		} else if (quizState === 'showSadFace' && !reachedAttemptLimit) {
			timeoutId = setTimeout(() => setQuizState('showReset'), 2000);
		}

		return () => clearTimeout(timeoutId);
	}, [quizState]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="quiz-submit">
			<Fade in={quizState === 'unsubmitted' && allAnswersSelected} mountOnEnter unmountOnExit>
				<div>
					<button
						type="submit"
						className="btn btn-icon btn-quiz-forward position-absolute left-50 translate-middle-x"
						aria-label="Submit"
						onClick={() => {
							submitActivityProgress({
								bookId,
								activityId,
								isSuccessful: allAnswersCorrect
							});
						}}
					>
						<SvgMask path="/svg/arrow-right.svg" width={48} height={48} />
					</button>
				</div>
			</Fade>
			<Fade
				in={quizState === 'showSadFace'}
				mountOnEnter
				unmountOnExit
				transitionClasses={{
					entered: 'shake'
				}}
			>
				<div>
					<div className="btn btn-icon btn-quiz-forward error pe-none position-absolute left-50 translate-middle-x">
						<SvgMask path="/svg/reaction-discontent.svg" width={48} height={48} />
					</div>
				</div>
			</Fade>
			<Fade
				in={quizState === 'showHappyFace'}
				mountOnEnter
				unmountOnExit
				transitionClasses={{
					entered: 'raise-up'
				}}
			>
				<div>
					<div className="btn btn-icon btn-quiz-forward success pe-none position-absolute left-50 translate-middle-x">
						<SvgMask path="/svg/reaction-joy.svg" width={48} height={48} />
					</div>
				</div>
			</Fade>
			<Fade in={quizState === 'showReset'} mountOnEnter unmountOnExit>
				<div>
					<button
						type="button"
						onClick={() => {
							setQuizState('unsubmitted');
							handleReset();
						}}
						className="btn btn-icon btn-quiz-forward position-absolute left-50 translate-middle-x"
					>
						<SvgMask path="/svg/redo.svg" width={48} height={48} />
					</button>
				</div>
			</Fade>
			<Fade in={quizState === 'canProceed'} mountOnEnter unmountOnExit>
				<div>
					<Link
						to={nextQuestionRoute}
						relative="path"
						className="btn btn-icon btn-quiz-forward success position-absolute left-50 translate-middle-x"
						reloadDocument
					>
						<SvgMask path="/svg/arrow-right.svg" width={48} height={48} />
						<span className="visually-hidden">Proceed</span>
					</Link>
				</div>
			</Fade>
		</div>
	);
};

export default QuizSubmit;
